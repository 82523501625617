import { PHONE_REGEXP, NAME_REGEXP } from "./validation_methods";
import GenericFlatForm from "./components/flat-forms/GenericFlatForm";

export function initBuyNumberOrderForm() {
  let validator = {
    rules: {
      contact_person: {
        required: true,
        name_regexp: NAME_REGEXP
      },
      contact_number: {
        required: true,
        regexp: PHONE_REGEXP
      },
      number: {
        required: true
      },
      email: {
        required: true,
        email: true
      }
    }
  };
  new GenericFlatForm("#order-number-form", validator);
}
