import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  articlePropTypes,
  devicePropTypes,
  devicesCatalogPropTypes,
  flatpagePropTypes,
  newsPropTypes,
  numberPropTypes,
  numbersCatalogPropTypes,
  tariffPropTypes,
  tariffsCatalogPropTypes
} from "../../../../common/react/components/prop_types";
import ProviderIcon from "../../../../common/react/components/ProviderIcon";
import CurrencySymbol from "../../../../common/react/components/CurrencySymbol";

function escapeRegExp(str) {
  return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
}

function highlight(string, query) {
  let regex = new RegExp(escapeRegExp(query), "gi");
  return {
    __html: string.replace(regex, str => {
      return '<span class="search-popup__match">' + str + "</span>";
    })
  };
}

class TariffSuggestion extends React.Component {
  render() {
    const nameInner = highlight(this.props.tariff.name, this.props.query);
    const monthFee = (
      <div>
        Абонентская плата {this.props.tariff.month_fee}
        <CurrencySymbol currency={this.props.tariff.currency} />
        /мес
      </div>
    );

    return (
      <a href={this.props.tariff.absolute_url} className="search-popup__item">
        <div className="search-popup__item-left">
          <ProviderIcon
            provider={this.props.tariff.provider_object}
            small
            className="search-popup__icon"
          />
          <div className="search-popup__content">
            <div
              className="search-popup__name"
              dangerouslySetInnerHTML={nameInner}
            />
            <div className="search-popup__comment">{monthFee}</div>
          </div>
        </div>
        <div className="search-popup__price">
          <span className="price-inline">
            {this.props.tariff.current_price}
            <CurrencySymbol currency={this.props.tariff.currency} />
          </span>
        </div>
      </a>
    );
  }
}

TariffSuggestion.propTypes = {
  query: PropTypes.string,
  tariff: tariffPropTypes
};

class DeviceSuggestion extends React.Component {
  render() {
    const nameInner = highlight(this.props.device.name, this.props.query);

    return (
      <a href={this.props.device.absolute_url} className="search-popup__item">
        <div className="search-popup__item-left">
          <div className="search-popup__content">
            <div
              className="search-popup__name"
              dangerouslySetInnerHTML={nameInner}
            />
          </div>
        </div>
        <div className="search-popup__price">
          <span className="price-inline">
            {this.props.device.current_price}
            <CurrencySymbol currency={this.props.device.currency} />
          </span>
        </div>
      </a>
    );
  }
}

DeviceSuggestion.propTypes = {
  query: PropTypes.string,
  device: devicePropTypes
};

class NumberSuggestion extends React.Component {
  render() {
    const numberInner = highlight(this.props.number.number, this.props.query);

    let price = "";
    if (this.props.number.archive) {
      price = <span className="price-inline">&nbsp;</span>;
    } else if (this.props.number.has_price_on_call) {
      price = <span className="price-inline">Звоните</span>;
    } else {
      price = (
        <span className="price-inline">
          {this.props.number.site_price}
          <CurrencySymbol currency={this.props.number.currency} />
        </span>
      );
    }

    return (
      <a href={this.props.number.absolute_url} className="search-popup__item">
        <div className="search-popup__item-left">
          <ProviderIcon
            provider={this.props.number.provider_object}
            small
            className="search-popup__icon"
          />
          <div className="search-popup__content">
            <div
              className="search-popup__name"
              dangerouslySetInnerHTML={numberInner}
            />
          </div>
        </div>
        <div className="search-popup__price">{price}</div>
      </a>
    );
  }
}

NumberSuggestion.propTypes = {
  query: PropTypes.string,
  number: numberPropTypes
};

class NumbersCatalogSuggestion extends React.Component {
  renderIcon() {
    if (!this.props.catalog.provider_object) {
      return null;
    }

    return (
      <ProviderIcon
        provider={this.props.catalog.provider_object}
        small
        className="search-popup__icon"
      />
    );
  }

  render() {
    const titleInner = highlight(this.props.catalog.title, this.props.query);

    return (
      <a href={this.props.catalog.absolute_url} className="search-popup__item">
        <div className="search-popup__item-left">
          {this.renderIcon()}
          <div className="search-popup__content">
            <div
              className="search-popup__name"
              dangerouslySetInnerHTML={titleInner}
            />
          </div>
        </div>
      </a>
    );
  }
}

NumbersCatalogSuggestion.propTypes = {
  query: PropTypes.string,
  catalog: numbersCatalogPropTypes
};

class TariffsCatalogSuggestion extends React.Component {
  renderIcon() {
    if (!this.props.catalog.provider_object) {
      return null;
    }

    return (
      <ProviderIcon
        provider={this.props.catalog.provider_object}
        small
        className="search-popup__icon"
      />
    );
  }

  render() {
    const titleInner = highlight(this.props.catalog.title, this.props.query);

    return (
      <a href={this.props.catalog.absolute_url} className="search-popup__item">
        <div className="search-popup__item-left">
          {this.renderIcon()}
          <div className="search-popup__content">
            <div
              className="search-popup__name"
              dangerouslySetInnerHTML={titleInner}
            />
          </div>
        </div>
      </a>
    );
  }
}

TariffsCatalogSuggestion.propTypes = {
  query: PropTypes.string,
  catalog: tariffsCatalogPropTypes
};

class DevicesCatalogSuggestion extends React.Component {
  render() {
    const iconClassName = "search-popup__icon icon-small";
    const titleInner = highlight(this.props.catalog.title, this.props.query);

    return (
      <a href={this.props.catalog.absolute_url} className="search-popup__item">
        <div className="search-popup__item-left">
          <div className={iconClassName} />
          <div className="search-popup__content">
            <div
              className="search-popup__name"
              dangerouslySetInnerHTML={titleInner}
            />
          </div>
        </div>
      </a>
    );
  }
}

DevicesCatalogSuggestion.propTypes = {
  query: PropTypes.string,
  catalog: devicesCatalogPropTypes
};

class NewsSuggestion extends React.Component {
  render() {
    const titleInner = highlight(this.props.news.title, this.props.query);

    return (
      <a href={this.props.news.absolute_url} className="search-popup__item">
        <div className="search-popup__item-left">
          <div className="search-popup__icon icon-articles" />
          <div className="search-popup__content">
            <div
              className="search-popup__name"
              dangerouslySetInnerHTML={titleInner}
            />
            <div className="search-popup__comment">
              {this.props.news.date_publish_display}
            </div>
          </div>
        </div>
      </a>
    );
  }
}

NewsSuggestion.propTypes = {
  query: PropTypes.string,
  news: newsPropTypes
};

class ArticleSuggestion extends React.Component {
  render() {
    const titleInner = highlight(this.props.article.title, this.props.query);

    return (
      <a href={this.props.article.absolute_url} className="search-popup__item">
        <div className="search-popup__item-left">
          <div className="search-popup__icon icon-articles" />
          <div className="search-popup__content">
            <div
              className="search-popup__name"
              dangerouslySetInnerHTML={titleInner}
            />
            <div className="search-popup__comment">
              {this.props.article.date_publish_display}
            </div>
          </div>
        </div>
      </a>
    );
  }
}

ArticleSuggestion.propTypes = {
  query: PropTypes.string,
  article: articlePropTypes
};

class FlatpageSuggestion extends React.Component {
  render() {
    const titleInner = highlight(this.props.flatpage.title, this.props.query);

    return (
      <a href={this.props.flatpage.absolute_url} className="search-popup__item">
        <div className="search-popup__item-left">
          <div className="search-popup__icon icon-articles" />
          <div className="search-popup__content">
            <div
              className="search-popup__name"
              dangerouslySetInnerHTML={titleInner}
            />
          </div>
        </div>
      </a>
    );
  }
}

FlatpageSuggestion.propTypes = {
  query: PropTypes.string,
  flatpage: flatpagePropTypes
};

export class SearchSuggestions extends React.Component {
  render() {
    const results = this.props.results;
    const query = this.props.query;
    const showPreloader = this.props.showPreloader;

    const displayNone = {
      display: "none"
    };

    if (showPreloader) {
      return (
        <div className="search-popup" style={displayNone}>
          <div className="preloader-wrapper">
            <div className="preloader-small" />
          </div>
        </div>
      );
    }

    const searchUrl = "/search/?query=" + this.props.query;
    const tariffSuggestions = results.tariffs.map((tariff, i) => (
      <TariffSuggestion key={tariff.id} tariff={tariff} query={query} />
    ));
    const numberSuggestions = results.numbers.map((number, i) => (
      <NumberSuggestion key={number.id} number={number} query={query} />
    ));
    const deviceSuggestions = results.devices.map((device, i) => (
      <DeviceSuggestion key={device.id} device={device} query={query} />
    ));
    const newsSuggestions = results.news.map((news, i) => (
      <NewsSuggestion key={news.id} news={news} query={query} />
    ));
    const articleSuggestions = results.articles.map((article, i) => (
      <ArticleSuggestion key={article.id} article={article} query={query} />
    ));
    const flatpageSuggestions = results.flatpages.map((flatpage, i) => (
      <FlatpageSuggestion key={flatpage.id} flatpage={flatpage} query={query} />
    ));
    const numbersCatalogSuggestions = results.numbers_catalogs.map(
      (catalog, i) => (
        <NumbersCatalogSuggestion
          key={catalog.id}
          catalog={catalog}
          query={query}
        />
      )
    );
    const tariffsCatalogSuggestions = results.tariffs_catalogs.map(
      (catalog, i) => (
        <TariffsCatalogSuggestion
          key={catalog.id}
          catalog={catalog}
          query={query}
        />
      )
    );
    const devicesCatalogSuggestions = results.devices_catalogs.map(
      (catalog, i) => (
        <DevicesCatalogSuggestion
          key={catalog.id}
          catalog={catalog}
          query={query}
        />
      )
    );
    const empty =
      this.props.results.total > 0 ? (
        ""
      ) : (
        <div className="search-popup__empty">Ничего не найдено</div>
      );
    const all =
      this.props.results.total === 0 ? (
        ""
      ) : (
        <a href={searchUrl} className="search-popup__all">
          Показать все результаты<span>&nbsp;({this.props.results.total})</span>
        </a>
      );

    return (
      <div className="search-popup" style={displayNone}>
        {tariffSuggestions}
        {numberSuggestions}
        {deviceSuggestions}
        {numbersCatalogSuggestions}
        {tariffsCatalogSuggestions}
        {devicesCatalogSuggestions}
        {newsSuggestions}
        {articleSuggestions}
        {flatpageSuggestions}
        {empty}
        {all}
      </div>
    );
  }
}

SearchSuggestions.propTypes = {
  results: PropTypes.shape({
    tariffs: PropTypes.arrayOf(tariffPropTypes),
    numbers: PropTypes.arrayOf(numberPropTypes),
    devices: PropTypes.arrayOf(devicePropTypes),
    news: PropTypes.arrayOf(newsPropTypes),
    articles: PropTypes.arrayOf(articlePropTypes),
    flatpages: PropTypes.arrayOf(flatpagePropTypes),
    numbers_catalogs: PropTypes.arrayOf(numbersCatalogPropTypes),
    tariffs_catalogs: PropTypes.arrayOf(tariffsCatalogPropTypes),
    devices_catalogs: PropTypes.arrayOf(devicesCatalogPropTypes),
    total: PropTypes.number
  }),
  query: PropTypes.string,
  showPreloader: PropTypes.bool
};

export function mapStateToProps(state) {
  return {
    results: state.search.suggestions,
    query: state.search.query,
    showPreloader: state.search.showPreloader
  };
}

export default connect(mapStateToProps)(SearchSuggestions);
