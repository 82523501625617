import {randomString} from "./utils";
import {SideTabbed} from "./side-tabbed";

function createSideTabbed() {
  const base = document.createElement("div");
  base.classList.add("side-tabbed");

  const tabsColumn = document.createElement("div");
  tabsColumn.classList.add("side-tabbed__tabs-column");
  base.appendChild(tabsColumn);

  const contentColumn = document.createElement("div");
  contentColumn.classList.add("side-tabbed__content-column");
  base.appendChild(contentColumn);

  return {base, tabsColumn, contentColumn};
}

function collectItems(infoAccordion) {
  const itemElems = infoAccordion.querySelectorAll(".info-accordion__item");
  const items = [];
  for (let i = 0; i < itemElems.length; i++) {
    const elem = itemElems[i];
    const title = elem.querySelector(".info-accordion__title");
    const content = elem.querySelector(".info-accordion__content");
    const isActive = elem.classList.contains("info-accordion__item--active");

    if (!title || !content) {
      continue;
    }

    items.push({title, content, isActive});
  }

  return items;
}

function copyItemsToSideTabbed(sideTabbed, items) {
  for (const item of items) {
    const itemId = `tab-${randomString()}`;

    const tabbedTitle = item.title.cloneNode(true);
    tabbedTitle.classList.remove("info-accordion__title");
    tabbedTitle.classList.add("side-tabbed__tab");
    tabbedTitle.setAttribute("data-target", `#${itemId}`);

    const tabbedContent = item.content.cloneNode(true);
    tabbedContent.classList.remove("info-accordion__content");
    tabbedContent.classList.add("side-tabbed__content");
    tabbedContent.setAttribute("id", itemId);

    if (item.isActive) {
      tabbedTitle.classList.add("side-tabbed__tab--active");
      tabbedContent.classList.add("side-tabbed__content--active");
    }

    sideTabbed.tabsColumn.appendChild(tabbedTitle);
    sideTabbed.contentColumn.appendChild(tabbedContent);
  }
}

function constructInfoAccordionToTabbed(baseElement) {
  const breakpoint = baseElement.getAttribute("data-breakpoint");

  const infoAccordion = baseElement.querySelector(".info-accordion");
  infoAccordion.classList.add(`d-${breakpoint}-none`);
  const items = collectItems(infoAccordion);

  const sideTabbed = createSideTabbed();
  sideTabbed.base.classList.add(`d-xs-${breakpoint}-none`);
  baseElement.appendChild(sideTabbed.base);
  copyItemsToSideTabbed(sideTabbed, items);
  new SideTabbed(sideTabbed.base);
}

export default function initInfoAccordionToTabbed() {
  let elements = document.querySelectorAll(".info-accordion-to-tabbed");
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    constructInfoAccordionToTabbed(element);
  }
}
