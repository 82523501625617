import lodashTemplate from "lodash/template";
import forEach from "lodash/forEach";

import searchNumberListTemplate from "../../../templates/common/search_numbers_list.ejs";
import searchTariffListTemplate from "../../../templates/common/search_tariffs_list.ejs";
import searchDeviceListTemplate from "../../../templates/common/search_devices_list.ejs";
import searchNewsListTemplate from "../../../templates/common/search_news_list.ejs";
import searchArticleListTemplate from "../../../templates/common/search_articles_list.ejs";
import searchFlatpageListTemplate from "../../../templates/common/search_flatpages_list.ejs";
import searchNumbersCatalogsListTemplate from "../../../templates/common/search_numbers_catalogs_list.ejs";
import searchTariffsCatalogsListTemplate from "../../../templates/common/search_tariffs_catalogs_list.ejs";
import searchDevicesCatalogsListTemplate from "../../../templates/common/search_devices_catalogs_list.ejs";
import { renderProviderIcon } from "../../templates";

const searchTypes = {
  numbers: searchNumberListTemplate,
  tariffs: searchTariffListTemplate,
  devices: searchDeviceListTemplate,
  news: searchNewsListTemplate,
  articles: searchArticleListTemplate,
  flatpages: searchFlatpageListTemplate,
  numbersCatalogs: searchNumbersCatalogsListTemplate,
  tariffsCatalogs: searchTariffsCatalogsListTemplate,
  devicesCatalogs: searchDevicesCatalogsListTemplate
};

export default class GenericSearcher {
  constructor(baseElement) {
    // CONST
    this.baseElement = baseElement;
    this.page = 2;
    this.isLoading = false;
    // Findable
    this.searchType = this.baseElement.attr("data-search-type");
    this.searchMoreButton = this.baseElement.parent().find(".search-more");
    this.searchMoreButtonContainer = this.baseElement
      .parent()
      .find(".js-search-more-container");
    this.queryInput = this.baseElement.find("input[name=query]");

    this.searchMoreButton.on("click", e => {
      e.preventDefault();
      this.searchEvent();
    });
  }

  prepareData() {
    let params = new URLSearchParams(this.baseElement.serialize());
    params.set("page", this.page.toString());
    return params.toString();
  }

  sendRequest() {
    let data = this.prepareData();
    let endpoint = this.baseElement.attr("data-load-more-endpoint");
    let url = [endpoint, data].join("?");

    return $.ajax({
      url: url
    });
  }

  searchEvent() {
    if (this.isLoading) return;
    this.isLoading = true;
    this.searchMoreButton.addClass("search-more--loading");

    this.sendRequest()
      .then(data => {
        this.page += 1;
        if (!data.next) {
          this.searchMoreButtonContainer.hide();
        }
        let html = this.renderTemplate(data.results);
        this.searchMoreButtonContainer.before(html);
      })
      .fail(() => {
        this.searchMoreButtonContainer.hide();
      })
      .always(() => {
        this.isLoading = false;
        this.searchMoreButton.removeClass("search-more--loading");
      });
  }

  getTemplate() {
    return searchTypes[this.searchType];
  }

  renderTemplate(results) {
    let template = lodashTemplate(this.getTemplate(), {
      imports: {
        forEach: forEach,
        renderProviderIcon: renderProviderIcon
      }
    });
    return template({
      results: results
    });
  }
}
