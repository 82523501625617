import { INFINITY } from "./internet-range-input-group";
import tariffCardTemplate from "../templates/common/tariff_card.ejs";
import lodashTemplate from "lodash/template";
import forEach from "lodash/forEach";
import {
  renderNoResultsMessage,
  renderProviderIcon
} from "../common/templates";
import { store } from "./react/store";
import {addButtonPreloader, getPrice, plural, prettyNum, removeButtonPreloader} from "./utils";
import initTooltipster from "./tooltipster";
import debounce from "lodash/debounce";

const SORT_FIELD = "month_fee_extra";
const SORT_ASC_CLS = "numbers-sort--up";
const SORT_DESC_CLS = "numbers-sort--down";

class TariffCalculator {
  constructor(baseElement) {
    this.baseElement = baseElement;
    this.url = this.baseElement.getAttribute("data-url");
    this.formTab = this.baseElement.querySelector(
      ".tariff-calculator__form-tab"
    );
    this.resultsTab = this.baseElement.querySelector(
      ".tariff-calculator__results-tab"
    );
    this.resultsContent = this.baseElement.querySelector(
      ".tariff-calculator__results-content"
    );
    this.submitButton = this.baseElement.querySelector(
      ".tariff-calculator__submit-button"
    );
    this.buttonMore = this.baseElement.querySelector(
      ".tariff-calculator__button-more"
    );
    this.returnButton = this.baseElement.querySelector(
      ".tariff-calculator__return-button"
    );
    this.sortButton = this.baseElement.querySelector(
      ".tariff-calculator__sort"
    );
    this.countElem = this.baseElement.querySelector(
      ".tariff-calculator__count"
    );
    this.submitDebounced = debounce(this.submit.bind(this), 500);

    this.pageSize = 10;
    this.loadedCount = 0;

    this.cardTemplate = lodashTemplate(tariffCardTemplate, {
      imports: {
        forEach,
        renderProviderIcon,
        getMonthlySpendings,
        getOptionName
      }
    });

    this.submitButton.addEventListener("click", () => {
      this.scheduleReload();
    });
    this.buttonMore.addEventListener("click", () => {
      this.scheduleLoadMore();
    });
    this.returnButton.addEventListener("click", () => {
      this.returnToForm();
    });
    this.sortButton.addEventListener("click", () => {
      this.sort();
    });
  }

  getFilterInputValue(name) {
    const inputs = this.formTab.querySelectorAll(`[name="${name}"]`);
    for (let i = 0; i < inputs.length; i++) {
      const input = inputs[i];
      if (input.type == "checkbox" || input.type == "radio") {
        if (input.checked) {
          return input.value;
        } else {
          continue;
        }
      } else {
        return input.value;
      }
    }
    return undefined;
  }

  getProviderFilterValue() {
    const inputs = this.formTab.querySelectorAll('[name="provider"]');
    const providers = [];
    for (let i = 0; i < inputs.length; i++) {
      const input = inputs[i];
      if (input.checked && input.value) {
        providers.push(input.value);
      }
    }
    return providers.join(",");
  }

  getCallsFilterValue() {
    const callsPerDay = this.getFilterInputValue("calls") || 0;
    const callsSameProviderPc =
      this.getFilterInputValue("calls_same_provider") || 0;
    const callsOtherProvidersPc =
      this.getFilterInputValue("calls_other_providers") || 0;
    const callsInternationalPc =
      this.getFilterInputValue("calls_international") || 0;

    const callsPerMonth = callsPerDay * 30;
    const callsSameProvider = Math.round(
      (callsPerMonth * callsSameProviderPc) / 100
    );
    const callsOtherProviders = Math.round(
      (callsPerMonth * callsOtherProvidersPc) / 100
    );
    const callsInternational = Math.round(
      (callsPerMonth * callsInternationalPc) / 100
    );

    return `${callsSameProvider},${callsOtherProviders},${callsInternational}`;
  }

  getSmsFilterValue() {
    const smsPerDay = this.getFilterInputValue("sms") || 0;
    return smsPerDay * 30;
  }

  getGbInputValue() {
    const input = this.formTab.querySelector('[name="gb"]');
    if (!input) {
      return 0;
    }

    const gb = input.value || 0;
    return gb;
  }

  getGbFilterValue() {
    const gb = this.getGbInputValue();
    if (gb === INFINITY) {
      return undefined;
    } else {
      return Number(gb);
    }
  }

  getGbUnlimFilterValue() {
    const gb = this.getGbInputValue();
    if (gb === INFINITY) {
      return "true";
    } else {
      return undefined;
    }
  }

  isOrderingAsc() {
    if (this.sortButton.classList.contains(SORT_ASC_CLS)) {
      return true;
    } else if (this.sortButton.classList.contains(SORT_DESC_CLS)) {
      return false;
    } else {
      return true;
    }
  }

  getOrdering() {
    if (this.isOrderingAsc()) {
      return SORT_FIELD;
    } else {
      return `-${SORT_FIELD}`;
    }
  }

  async sort() {
    if (this.isOrderingAsc()) {
      this.sortButton.classList.remove(SORT_ASC_CLS);
      this.sortButton.classList.add(SORT_DESC_CLS);
    } else {
      this.sortButton.classList.add(SORT_ASC_CLS);
      this.sortButton.classList.remove(SORT_DESC_CLS);
    }

    await this.submitDebounced();
  }

  getFilterData() {
    const data = new URLSearchParams();

    const subdomain = this.getFilterInputValue("subdomain");
    if (subdomain) {
      data.append("subdomain", subdomain);
    }

    const provider = this.getProviderFilterValue();
    if (provider) {
      data.append("provider", provider);
    }

    data.append("number_type", this.getFilterInputValue("number_type"));
    data.append("calls", this.getCallsFilterValue());
    data.append("sms", this.getSmsFilterValue());

    const gb = this.getGbFilterValue();
    if (gb !== undefined) {
      data.append("gb", gb);
    }

    const gbUnlim = this.getGbUnlimFilterValue();
    if (gbUnlim !== undefined) {
      data.append("gb_unlim", gbUnlim);
    }

    const includeOptions = this.getFilterInputValue("include_options");
    if (includeOptions) {
      data.append("include_options", "true");
    }

    data.append("ordering", this.getOrdering());

    return data;
  }

  renderContent(tariffs) {
    const state = store.getState();
    let html = "";
    for (const tariff of tariffs) {
      html += this.cardTemplate({
        tariff,
        showMonthlySpendings: true,
        cartTariffs: state.cart.cartTariffs
      })
    }
    return html;
  }

  async scheduleButtonAction(button, startedFlag, action) {
    if (this[startedFlag]) {
      return;
    }

    addButtonPreloader(button);
    this[startedFlag] = true;

    try {
      await action();
    } finally {
      this[startedFlag] = false;
      removeButtonPreloader(button);
    }
  }

  async scheduleReload() {
    this.scheduleButtonAction(this.submitButton, "reloadStarted", this.reload.bind(this));
  }

  async reload() {
    await this.submit();
    window.scrollTo({top: 0, behavior: "smooth"});
  }

  async submit() {
    const filterData = this.getFilterData();
    filterData.append("limit", this.pageSize);

    const url = `${this.url}?${filterData.toString()}`;
    const resp = await fetch(url);
    const respJson = await resp.json();

    let content;
    if (respJson.results.length > 0) {
      content = this.renderContent(respJson.results);
    } else {
      content = renderNoResultsMessage("Ничего не найдено");
    }

    this.resultsContent.innerHTML = content;
    this.resultsTab.style.display = "";
    this.formTab.style.display = "none";
    this.loadedCount = respJson.results.length;
    this.setCount(respJson.count, respJson.provider_count);

    if (respJson.count > this.loadedCount) {
      this.buttonMore.style.display = "";
    } else {
      this.buttonMore.style.display = "none";
    }

    initTooltipster(this.resultsContent);
  }

  async scheduleLoadMore() {
    this.scheduleButtonAction(this.buttonMore, "loadMoreStarted", this.loadMore.bind(this));
  }

  async loadMore() {
    const filterData = this.getFilterData();
    filterData.append("offset", this.loadedCount);
    filterData.append("limit", this.pageSize);

    const url = `${this.url}?${filterData.toString()}`;
    const resp = await fetch(url);
    const respJson = await resp.json();

    const content = this.renderContent(respJson.results);
    this.resultsContent.innerHTML += content;
    this.loadedCount += respJson.results.length;

    if (respJson.count > this.loadedCount) {
      this.buttonMore.style.display = "";
    } else {
      this.buttonMore.style.display = "none";
    }

    initTooltipster(this.resultsContent);
  }

  returnToForm() {
    this.resultsTab.style.display = "none";
    this.formTab.style.display = "";
  }

  setCount(tariffCount, providerCount) {
    const found = plural(tariffCount, "Найден", "Найдено", "Найдено");
    const tariffs = `${tariffCount} ${plural(
      tariffCount,
      "тариф",
      "тарифа",
      "тарифов"
    )}`;
    const providers = `${providerCount} ${plural(
      providerCount,
      "оператора",
      "операторов",
      "операторов"
    )}`;

    this.countElem.innerHTML = `${found} <b>${tariffs}</b> у <b>${providers}</b>.`;
  }
}

function safeNumber(val, def) {
  const num = getPrice(val);
  if (Number.isNaN(num)) {
    return def;
  } else {
    return num;
  }
}

function getMonthlySpendings(tariff) {
  let spendings = safeNumber(tariff.month_fee, 0);
  let options = tariff.included_options || [];
  for (const [, optionPrice] of options) {
    spendings += safeNumber(optionPrice, 0);
  }
  return prettyNum(spendings);
}

function getOptionName(optionId) {
  if (optionId == 1) {
    return "Безлимитные мессенджеры";
  } else if (optionId == 2) {
    return "Безлимитные соцсети";
  } else if (optionId == 3) {
    return "Безлимитные видео";
  } else {
    return "Безлимитные приложения";
  }
}

export default function initTariffCalculator() {
  const elements = document.querySelectorAll(".tariff-calculator");
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    new TariffCalculator(element);
  }
}
