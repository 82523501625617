import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { store } from "../../store";
import Subdomains from "./Subdomains";
import { getSubdomains } from "../../../../api";
import { getSubdomainsByLetter } from "../../../utils";
import {
  SUBDOMAIN_LIST_LOAD,
  SUBDOMAIN_FILTER_OUT,
  SUBDOMAIN_TOGGLE_FULL_LIST,
  SUBDOMAIN_TOGGLE_SHORT_LIST
} from "../../modules/subdomainReducer";

class SubdomainsContainer extends React.Component {
  componentDidMount() {
    getSubdomains().then(response => {
      let allSubdomains = response.results,
        columnsCount = response.columns_count,
        rowsCount = response.rows_count,
        limitSubdomains = columnsCount * rowsCount,
        shortListSubdomains = allSubdomains.slice(0, limitSubdomains),
        filterSubdomainsByLetter = getSubdomainsByLetter(allSubdomains);

      store.dispatch({
        type: SUBDOMAIN_LIST_LOAD,
        allSubdomains: allSubdomains,
        shortListSubdomains: shortListSubdomains,
        filterSubdomainsByLetter: filterSubdomainsByLetter,
        columnsCount: columnsCount,
        rowsCount: rowsCount
      });
    });
  }

  render() {
    return (
      <Subdomains
        shortListSubdomains={this.props.shortListSubdomains}
        filterSubdomainsByLetter={this.props.filterSubdomainsByLetter}
        filterOut={this.props.filterOut}
        rowsCount={this.props.rowsCount}
        columnsCount={this.props.columnsCount}
        isOpenFullList={this.props.isOpenFullList}
        toggleFullList={this.props.toggleFullList}
        isOpenShortList={this.props.isOpenShortList}
        toggleShortList={this.props.toggleShortList}
      />
    );
  }
}

SubdomainsContainer.propTypes = {
  isOpenShortList: PropTypes.bool,
  isOpenFullList: PropTypes.bool,
  rowsCount: PropTypes.number,
  columnsCount: PropTypes.number,
  shortListSubdomains: PropTypes.array,
  filterSubdomainsByLetter: PropTypes.array,
  allSubdomains: PropTypes.array,
  filterOut: PropTypes.func,
  toggleFullList: PropTypes.func,
  toggleShortList: PropTypes.func
};

const mapStateToProps = function(store) {
  return {
    allSubdomains: store.subdomainState.allSubdomains,
    shortListSubdomains: store.subdomainState.shortListSubdomains,
    filterSubdomainsByLetter: store.subdomainState.filterSubdomainsByLetter,
    columnsCount: store.subdomainState.columnsCount,
    rowsCount: store.subdomainState.rowsCount,
    isOpenFullList: store.subdomainState.isOpenFullList,
    isOpenShortList: store.subdomainState.isOpenShortList
  };
};

const mapDispatchToProps = function(dispatch) {
  return {
    filterOut: filterValue => {
      dispatch({ type: SUBDOMAIN_FILTER_OUT, filterValue: filterValue });
    },
    toggleFullList: () => {
      dispatch({ type: SUBDOMAIN_TOGGLE_FULL_LIST });
    },
    toggleShortList: () => {
      dispatch({ type: SUBDOMAIN_TOGGLE_SHORT_LIST });
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubdomainsContainer);
