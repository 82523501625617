import React from "react";
import ReactDOM from "react-dom";
import {store} from '../store';
import {getPrice} from '../../utils';
import {getFromApi, postToApi, putToApi, deleteFromApi, AjaxQueue} from '../../api';
import Cart from "../components/cart/Cart";
import {initCartCreator, initEmptyCartCreator, updateCartCreator} from "../reducers/cart";

const CART_API_URL = '/api/cart/';
const CART_ITEMS_API_URL = '/api/cart/items/';
const CART_PROMOCODE_API_URL = '/api/cart/promocode/';
export const SIM_TYPE_USIM = 4;
export const MAX_CART_ITEMS_ERROR = 'Слишком много элементов в корзине. Нельзя положить больше!';

let cartAjaxQueue = new AjaxQueue();


export function loadCart() {
    let data = {};

    if (window.EXPAND_CART_ITEMS) {
        data = 'expand=suitable_numbers,suitable_tariffs';
    }

    return getFromApi(CART_API_URL, data);
}


export function clearCart() {
    return cartAjaxQueue.push(() => deleteFromApi(CART_API_URL));
}


export function addNumberToCart(numberId, options) {
    const defaultOptions = { rentNumber: false, providerMaskFilter: null };
    options = {...defaultOptions, ...options};

    const state = store.getState();
    let intId = parseInt(numberId);

    if (state.cart.cartNumbers.indexOf(intId) !== -1) {
        return $.Deferred().reject('already_in_cart')
    }
    if (state.cart.items.length === window.MAX_CART_ITEMS) {
        return $.Deferred().reject('max_cart_items')
    } else {
        let data = {
            'number': numberId,
            'sim_type': SIM_TYPE_USIM,
        };
        if (options.rentNumber) {
            data['rent_period'] = window.MIN_RENT_NUMBER_PERIOD
        }
        if (options.providerMaskFilter) {
            data['number_provider_mask_filter'] = options.providerMaskFilter
        }
        return cartAjaxQueue.push(() => postToApi(CART_ITEMS_API_URL, data));
    }
}


export function addTariffToCart(tariffId) {
    const state = store.getState();
    let intId = parseInt(tariffId);

    if (state.cart.cartTariffs.indexOf(intId) !== -1) {
        return $.Deferred().reject('already_in_cart')
    }
    if (state.cart.items.length === window.MAX_CART_ITEMS) {
        return $.Deferred().reject('max_cart_items')
    } else {
        return cartAjaxQueue.push(() => postToApi(CART_ITEMS_API_URL, {'tariff': tariffId}));
    }
}


export function addDeviceToCart(deviceId) {
    const state = store.getState();
    let intId = parseInt(deviceId);

    if (state.cart.cartDevices.indexOf(intId) !== -1) {
        return $.Deferred().reject('already_in_cart')
    }
    if (state.cart.items.length === window.MAX_CART_ITEMS) {
        return $.Deferred().reject('max_cart_items')
    } else {
        return cartAjaxQueue.push(() => postToApi(CART_ITEMS_API_URL, {'device': deviceId}));
    }
}


export function removeFromCart(itemId) {
    return cartAjaxQueue.push(() => deleteFromApi(CART_ITEMS_API_URL + itemId + '/'));
}


export function removeNumberFromCartItem(itemId) {
    return cartAjaxQueue.push(() => putToApi(CART_ITEMS_API_URL + itemId + '/', {
        'number': null,
        'sim_type': null
    }));
}


export function removeTariffFromCartItem(itemId) {
    return cartAjaxQueue.push(() => putToApi(CART_ITEMS_API_URL + itemId + '/', {
        'tariff': null
    }));
}


export function removeDeviceFromCartItem(itemId) {
    return cartAjaxQueue.push(() => putToApi(CART_ITEMS_API_URL + itemId + '/', {
        'device': null
    }));
}


export function changeRentNumberPeriod(itemId, value) {
    return cartAjaxQueue.push(() => putToApi(CART_ITEMS_API_URL + itemId + '/', {
        'rent_period': value
    }));
}


export function changeRefillForCartItem(itemId, value) {
    return cartAjaxQueue.push(() => putToApi(CART_ITEMS_API_URL + itemId + '/', {
        'refill': value,
    }));
}


export function setCartItemSimType(itemId, number, simTypeId) {
    return cartAjaxQueue.push(() => putToApi(CART_ITEMS_API_URL + itemId + '/', {
        'number': number,
        'sim_type': simTypeId
    }));
}


export function setCartPromocode(code) {
    return cartAjaxQueue.push(() => putToApi(CART_PROMOCODE_API_URL, {
        'code': code,
    }));
}


export function clearCartPromocode() {
    return cartAjaxQueue.push(() => deleteFromApi(CART_PROMOCODE_API_URL));
}


export function handleAddNumberDataLayer(data) {
    window.dataLayer.push({
        'ecommerce': {
            'add': {
                'products': [
                    {
                        'id': data.number.prefixed_id,
                        'name': data.number.number_digit,
                        'price': getPrice(data.number.site_price),
                        'brand': data.number.provider_name,
                        'category': 'Номер',
                        'quantity': 1
                    }
                ]
            }
        },
        'event': 'gtm-ee-event',
        'gtm-ee-event-category': 'Enhanced Ecommerce',
        'gtm-ee-event-action': 'Adding a Product to a Shopping Cart',
        'gtm-ee-event-non-interaction': 'False',
    });
}


export function handleRemoveNumberDataLayer(data) {
    window.dataLayer.push({
        'ecommerce': {
            'remove': {
                'products': [
                    {
                        'id': data.removed_number.prefixed_id,
                        'name': data.removed_number.number_digit,
                        'price': getPrice(data.removed_number.site_price),
                        'brand': data.removed_number.provider_name,
                        'category': 'Номер',
                        'quantity': 1
                    }
                ]
            }
        },
        'event': 'gtm-ee-event',
        'gtm-ee-event-category': 'Enhanced Ecommerce',
        'gtm-ee-event-action': 'Removing a Product from a Shopping Cart',
        'gtm-ee-event-non-interaction': 'False',
    });
}


export function handleAddTariffDataLayer(data) {
    window.dataLayer.push({
        'ecommerce': {
            'add': {
                'products': [
                    {
                        'id': data.tariff.prefixed_id,
                        'name': data.tariff.name,
                        'price': getPrice(data.tariff.current_price),
                        'brand': data.tariff.provider_name,
                        'category': 'Тариф',
                        'quantity': 1
                    }
                ]
            }
        },
        'event': 'gtm-ee-event',
        'gtm-ee-event-category': 'Enhanced Ecommerce',
        'gtm-ee-event-action': 'Adding a Product to a Shopping Cart',
        'gtm-ee-event-non-interaction': 'False',
    });
}


export function handleRemoveTariffDataLayer(data) {
    window.dataLayer.push({
        'ecommerce': {
            'remove': {
                'products': [
                    {
                        'id': data.removed_tariff.prefixed_id,
                        'name': data.removed_tariff.name,
                        'price': getPrice(data.removed_tariff.current_price),
                        'brand': data.removed_tariff.provider_name,
                        'category': 'Тариф',
                        'quantity': 1
                    }
                ]
            }
        },
        'event': 'gtm-ee-event',
        'gtm-ee-event-category': 'Enhanced Ecommerce',
        'gtm-ee-event-action': 'Removing a Product from a Shopping Cart',
        'gtm-ee-event-non-interaction': 'False',
    });
}


export function handleAddDeviceDataLayer(data) {
    window.dataLayer.push({
        "ecommerce": {
            "add": {
                "products": [
                    {
                        "id": data.device.prefixed_id,
                        "name": data.device.name,
                        "price": getPrice(data.device.current_price),
                        "brand": data.device.manufacturer_name,
                        "category": data.device.device_type_name,
                        "quantity": 1
                    }
                ]
            }
        },
        'event': 'gtm-ee-event',
        'gtm-ee-event-category': 'Enhanced Ecommerce',
        'gtm-ee-event-action': 'Adding a Product to a Shopping Cart',
        'gtm-ee-event-non-interaction': 'False',
    });
}


export function handleRemoveDeviceDataLayer(data) {
    window.dataLayer.push({
        "ecommerce": {
            "remove": {
                "products": [
                    {
                        "id": data.removed_device.prefixed_id,
                        "name": data.removed_device.name,
                        "price": getPrice(data.removed_device.current_price),
                        "brand": data.removed_device.manufacturer_name,
                        "category": data.removed_device.device_type_name,
                        "quantity": 1
                    }
                ]
            }
        },
        'event': 'gtm-ee-event',
        'gtm-ee-event-category': 'Enhanced Ecommerce',
        'gtm-ee-event-action': 'Removing a Product from a Shopping Cart',
        'gtm-ee-event-non-interaction': 'False',
    });
}


export function toggleAddButton(btn) {
    if (btn.hasClass('round-cart-button')) {
        btn.toggleClass('round-cart-button--active')
    } else {
        btn.toggleClass('button--active')
    }
}


export function disableAddButtonByItemId(itemId, rentNumber=false) {
    let selector = '.add-to-cart[data-id="' + itemId + '"]';
    if (rentNumber) {
        selector += ':not([data-type="rent_number"])'
    } else {
        selector += ':not([data-type="number"])';
    }

    let btn = $(selector);
    btn.off('click');

    if (btn.hasClass('round-cart-button')) {
        btn.toggleClass('round-cart-button--disabled')
    } else {
        btn.toggleClass('button--disabled')
    }
}


export function initAddButtons() {
    let $btns = $('.add-to-cart');
    $btns.each((i, btn) => {
        let $btn = $(btn),
            btnHtml = $btn.html().trim();

        if (btnHtml && !$btn.data("btnOriginalHtml")) {
            $btn.data("btnOriginalHtml", btnHtml);
        }

        if (btnHtml && $btn.hasClass("button--active")) {
            $btn.text("Оформить");
        }
    });
}


export function toggleAddButtonAfterRemove(itemId) {
    let $items = $('.add-to-cart[data-id="' + itemId + '"]');
    $items.removeClass('button--active button--disabled round-cart-button--active');
    $items.off('click');
    $items.each((i, item) => {
        let $item = $(item),
            itemText = $item.text().trim(),
            itemOriginalHtml = $item.data("btnOriginalHtml") || $item.attr("data-original-btn-html") || "В корзину";
        if (itemText && itemText === 'Оформить') {
            $item.html(itemOriginalHtml);
        }
    })
}


export function toggleAddRelatedDeviceButtonAfterRemove() {
    let $items = $('.add-to-cart[data-type="related_devices"]');
    $items.removeClass('button--active button--disabled round-cart-button--active');
    $items.off('click');
    $items.each((i, item) => {
        let $item = $(item),
            itemText = $item.text().trim(),
            itemOriginalHtml = $item.data("btnOriginalHtml") || $item.attr("data-original-btn-html") || "В корзину";
        if (itemText && itemText === 'Оформить') {
            $item.html(itemOriginalHtml)
        }
    })
}

function renderCart() {
    const target = document.getElementById("cart");
    if (target) {
        ReactDOM.render(React.createElement(Cart, { store: store }), target);
    }
}

export function initCart() {
    const state = store.getState();

    renderCart();

    if (state.cart.itemsCount > 0 || location.pathname == CART_URL) {
        loadCart().done(data => {
            store.dispatch(initCartCreator(data));
        });
    } else {
        store.dispatch(initEmptyCartCreator());
    }

    $("body").on("click", ".add-to-cart", function(e) {
        let btn = $(this),
            btnHtml = btn.html().trim(),
            preloader = '<div class="preloader-xsmall"></div>',
            id = btn.data("id"),
            providerMaskFilter = btn.data("providerMaskFilter"),
            itemType = btn.data("type");

        if (btn.hasClass("button--disabled")) {
            return;
        }

        btn.html(preloader);
        btn.prop("disabled", true);

        btn.click(e => {
            window.location = "/order/";
        });

        if (itemType === "number") {
            addNumberToCart(id, { providerMaskFilter })
                .done(data => {
                    handleAddNumberDataLayer(data);
                    loadCart().done(data => {
                        toggleAddButton(btn);
                        disableAddButtonByItemId(id, false);
                        store.dispatch(updateCartCreator(data));
                    });
                    if (btnHtml) {
                        btn.text("Оформить");
                    }
                    btn.find(".preloader-xsmall").detach();
                    btn.prop("disabled", false);
                })
                .fail(context => {
                    if (context === "already_in_cart") {
                        window.location = "/order/";
                    } else if (context === "max_cart_items") {
                        alert(MAX_CART_ITEMS_ERROR);
                    }
                    if (btnHtml) {
                        btn.html(btnHtml);
                    }
                    btn.prop("disabled", true);
                });
        } else if (itemType === "number_set") {
            let flagInCart = true,
                flagSuccessAdding = false,
                promiseSequence = Promise.resolve();
            id.forEach(intId => {
                promiseSequence = promiseSequence.then(
                    () =>
                        new Promise(function(resolve) {
                            addNumberToCart(intId, { providerMaskFilter })
                                .done(data => {
                                    flagInCart = false;
                                    flagSuccessAdding = true;
                                    handleAddNumberDataLayer(data);
                                    resolve();
                                })
                                .fail(context => {
                                    if (context === "max_cart_items") {
                                        alert(MAX_CART_ITEMS_ERROR);
                                    }
                                    resolve();
                                });
                        })
                );
            });
            promiseSequence.then(() => {
                if (flagInCart) {
                    window.location = "/order/";
                }
                if (flagSuccessAdding) {
                    loadCart().done(data => {
                        toggleAddButton(btn);
                        // disableAddButtonByItemId(intId, false);
                        store.dispatch(updateCartCreator(data));
                    });
                    if (btnHtml) {
                        btn.text("Оформить");
                    }
                    btn.find(".preloader-xsmall").detach();
                    btn.prop("disabled", false);
                } else {
                    if (btnHtml) {
                        btn.html(btnHtml);
                    }
                    btn.prop("disabled", true);
                }
            });
        } else if (itemType === "rent_number") {
            addNumberToCart(id, { rentNumber: true, providerMaskFilter })
                .done(data => {
                    handleAddNumberDataLayer(data);
                    loadCart().done(data => {
                        toggleAddButton(btn);
                        disableAddButtonByItemId(id, true);
                        store.dispatch(updateCartCreator(data));
                    });
                    if (btnHtml) {
                        btn.text("Оформить");
                    }
                    btn.find(".preloader-xsmall").detach();
                    btn.prop("disabled", false);
                })
                .fail(context => {
                    if (context === "already_in_cart") {
                        window.location = "/order/";
                    } else if (context === "max_cart_items") {
                        alert(MAX_CART_ITEMS_ERROR);
                    }
                    if (btnHtml) {
                        btn.html(btnHtml);
                    }
                    btn.prop("disabled", true);
                });
        } else if (itemType === "rent_number_set") {
            let flagInCart = true,
                flagSuccessAdding = false,
                promiseSequence = Promise.resolve();
            id.forEach(intId => {
                promiseSequence = promiseSequence.then(
                    () =>
                        new Promise(function(resolve) {
                            addNumberToCart(intId, { rentNumber: true, providerMaskFilter })
                                .done(data => {
                                    flagInCart = false;
                                    flagSuccessAdding = true;
                                    handleAddNumberDataLayer(data);
                                    resolve();
                                })
                                .fail(context => {
                                    if (context === "max_cart_items") {
                                        alert(MAX_CART_ITEMS_ERROR);
                                    }
                                    resolve();
                                });
                        })
                );
            });
            promiseSequence.then(() => {
                if (flagInCart) {
                    window.location = "/order/";
                }
                if (flagSuccessAdding) {
                    loadCart().done(data => {
                        toggleAddButton(btn);
                        // disableAddButtonByItemId(intId, false);
                        store.dispatch(updateCartCreator(data));
                    });
                    if (btnHtml) {
                        btn.text("Оформить");
                    }
                    btn.find(".preloader-xsmall").detach();
                    btn.prop("disabled", false);
                } else {
                    if (btnHtml) {
                        btn.html(btnHtml);
                    }
                    btn.prop("disabled", true);
                }
            });
        } else if (itemType === "tariff") {
            addTariffToCart(id)
                .done(data => {
                    handleAddTariffDataLayer(data);
                    loadCart().done(data => {
                        toggleAddButton(btn);
                        store.dispatch(updateCartCreator(data));
                    });
                    if (btnHtml) {
                        btn.text("Оформить");
                    }
                    btn.find(".preloader-xsmall").detach();
                    btn.prop("disabled", false);
                })
                .fail(context => {
                    if (context === "already_in_cart") {
                        window.location = "/order/";
                    } else if (context === "max_cart_items") {
                        alert(MAX_CART_ITEMS_ERROR);
                    }
                    if (btnHtml) {
                        btn.html(btnHtml);
                    }
                    btn.prop("disabled", true);
                });
        } else if (itemType === "device") {
            addDeviceToCart(id)
                .done(data => {
                    handleAddDeviceDataLayer(data);
                    loadCart().done(data => {
                        toggleAddButton(
                            $('.add-to-cart[data-id="' + id + '"][data-type="device"]')
                        );
                        store.dispatch(updateCartCreator(data));
                        const state = store.getState();
                        if (state.cart.isRelatedDevicesInCart) {
                            toggleAddButton($('.add-to-cart[data-type="related_devices"]'));
                        }
                    });
                    if (btnHtml) {
                        btn.text("Оформить");
                    }
                    btn.find(".preloader-xsmall").detach();
                    btn.prop("disabled", false);
                })
                .fail(context => {
                    if (context === "already_in_cart") {
                        window.location = "/order/";
                    } else if (context === "max_cart_items") {
                        alert(MAX_CART_ITEMS_ERROR);
                    }
                    if (btnHtml) {
                        btn.html(btnHtml);
                    }
                    btn.prop("disabled", true);
                });
        } else if (itemType === "related_devices") {
            let devices = $(".product-set__product"),
                ids = [];

            const state = store.getState();

            // Добавляем только отсутствующие в корзине устройства
            devices.each(function() {
                let deviceId = $(this).data("device-id");
                if (state.cart.cartDevices.indexOf(deviceId) === -1) {
                    ids.push(deviceId);
                }
            });
            let promiseChain = Promise.resolve();
            ids.map(id => {
                promiseChain = promiseChain.then(() => addDeviceToCart(id));
                toggleAddButton(
                    $('.add-to-cart[data-id="' + id + '"][data-type="device"]')
                );
            });
            promiseChain.then(() => {
                loadCart().done(data => {
                    toggleAddButton(btn);
                    store.dispatch(updateCartCreator(data));
                });
                if (btnHtml) {
                    btn.text("Оформить");
                }
                btn.find(".preloader-xsmall").detach();
                btn.prop("disabled", false);
            });
        }

        e.preventDefault();
    });

    initAddButtons();
}
