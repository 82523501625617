import lodashTemplate from "lodash/template";
import forEach from "lodash/forEach";
import { store } from "../common/react/store";

import numbersTemplate from "../templates/desktop/numbers.html";
import { renderProviderIcon } from "../common/templates";

const preparedNumbersTemplate = lodashTemplate(numbersTemplate, {
  imports: {
    forEach: forEach,
    renderProviderIcon: renderProviderIcon
  }
});

export function renderNumbersList(data) {
  const state = store.getState();
  return preparedNumbersTemplate({
    newsList: data,
    subdomain: SUBDOMAIN_URL,
    cartNumbers: state.cart.cartNumbers
  });
}
