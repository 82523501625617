import { layoutUpdate, timeout } from "./utils";
import throttle from "lodash/throttle";

class Tab {
  constructor(baseElement) {
    this.baseElement = baseElement;
  }

  get target() {
    let targetSelector = this.baseElement.getAttribute("data-target");
    if (!targetSelector) return null;

    let target = document.querySelector(targetSelector);
    return target;
  }
}

export class SideTabbed {
  constructor(baseElement) {
    this.baseElement = baseElement;
    this.transitionDuration = 0.3; // В секундах
    this.currentTransitionId = 0;

    this.initContentColumn();
    this.initTransitions();
    this.initWindowWidthMonitor();

    for (const tab of this.targetedTabs) {
      tab.baseElement.addEventListener("click", () => {
        this.transitionTo(tab);
      });
    }
  }

  getActiveTab() {
    for (const tab of this.targetedTabs) {
      if (tab.target.classList.contains("side-tabbed__content--active")) {
        return tab;
      }
    }

    return null;
  }

  isActive(tab) {
    const activeTab = this.getActiveTab();
    if (!activeTab) {
      return false;
    }

    return activeTab.target === tab.target;
  }

  initContentColumn() {
    this.contentColumn.style.position = "relative";
    for (const tab of this.targetedTabs) {
      tab.target.style.position = "absolute";
    }

    const activeTab = this.getActiveTab();
    if (activeTab) {
      this.applyTabHeight(activeTab);
      this.bindTabHeight(activeTab);
    }
  }

  applyTabHeight(tab) {
    this.contentColumn.style.height = `${tab.target.scrollHeight}px`;
  }

  bindTabHeight(tab) {
    // Если один из <img> внутри вкладки загрузится, он может изменить высоту контента.
    // Нужно обновить высоту contentColumn.
    const images = tab.target.querySelectorAll("img");
    for (let i = 0; i < images.length; i++) {
      const image = images[i];
      image.addEventListener("load", () => {
        if (this.isActive(tab)) {
          this.applyTabHeight(tab);
        }
      });
    }
  }

  initWindowWidthMonitor() {
    const onResize = throttle(() => {
      const activeTab = this.getActiveTab();
      if (activeTab) {
        this.applyTabHeight(activeTab);
      }
    }, 200);

    window.addEventListener("resize", onResize);
  }

  initTransitions() {
    this.contentColumn.style.transitionProperty = "height";
    this.contentColumn.style.transitionDuration = `${this.transitionDuration}s`;

    for (const tab of this.targetedTabs) {
      tab.target.style.transitionProperty = "opacity";
      tab.target.style.transitionDuration = `${this.transitionDuration}s`;
    }
  }

  get contentColumn() {
    return this.baseElement.querySelector(".side-tabbed__content-column");
  }

  get targetedTabs() {
    let tabs = [];
    let tabElements = this.baseElement.querySelectorAll(".side-tabbed__tab");
    for (let i = 0; i < tabElements.length; i++) {
      const element = tabElements[i];
      let tab = new Tab(element);
      if (tab.target) {
        tabs.push(tab);
      }
    }
    return tabs;
  }

  transitionTo(targetTab) {
    this.currentTransitionId++;
    let transitionId = this.currentTransitionId;

    let previousActiveTabs = [];
    for (const otherTab of this.targetedTabs) {
      otherTab.baseElement.classList.remove("side-tabbed__tab--active");
      if (this.isActive(otherTab)) {
        previousActiveTabs.push(otherTab);
      }
    }

    targetTab.baseElement.classList.add("side-tabbed__tab--active");
    targetTab.target.style.opacity = "0";
    targetTab.target.classList.add("side-tabbed__content--active");
    this.applyTabHeight(targetTab);

    for (const otherTab of previousActiveTabs) {
      otherTab.target.style.opacity = "0";
    }

    return layoutUpdate()
      .then(() => {
        if (transitionId !== this.currentTransitionId) return;

        targetTab.target.style.opacity = "1";

        return timeout(this.transitionDuration * 1000);
      })
      .then(() => {
        if (transitionId !== this.currentTransitionId) return;

        for (const otherTab of previousActiveTabs) {
          otherTab.target.classList.remove("side-tabbed__content--active");
        }
      });
  }
}
