import { lazyLoadSlick } from "./lazy-load";

export default async function initCardSlider() {
  if ($(".card-slider").length === 0) return;

  await lazyLoadSlick();

  $(".card-slider").slick({
    dots: true,
    speed: 300,
    useTransform: true,
    touchThreshold: 20,
    infinite: false,
    centerMode: true,
    centerPadding: "12px",
    arrows: false
  });
}
