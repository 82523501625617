import { lazyLoadSlick } from "./lazy-load";

export default async function initInfoSlider() {
  if ($(".info-slider").length === 0) return;

  await lazyLoadSlick();

  $(".info-slider").each(function(index, element) {
    let slidesToShow = Number(element.getAttribute("data-slides-to-show") || 1);
    $(element).slick({
      dots: false,
      speed: 300,
      slidesToShow: slidesToShow,
      infinite: false,
      prevArrow:
        '<button class="info-slider__arrow info-slider__arrow--prev"></button>',
      nextArrow:
        '<button class="info-slider__arrow info-slider__arrow--next"></button>'
    });
  });
}
