import React from "react";
import ReactDOM from "react-dom";
import { initOrderCheckoutForm } from "../../../common/react/modules/order";
import { store } from "../../../common/react/store";
import {
  lazyLoadOrderCheckout,
  lazyLoadOrderResult
} from "../../../common/lazy-load";

export function renderOrderCheckout() {
  const elementId = "order-checkout";
  const target = document.getElementById(elementId);
  if (target) {
    lazyLoadOrderCheckout().then(({ default: OrderCheckout }) => {
      ReactDOM.render(
        React.createElement(OrderCheckout, { store: store }),
        target
      );
    });
  }
}

export function renderOrderResult() {
  const elementId = "order-result-items";
  const target = document.getElementById(elementId);
  if (target) {
    lazyLoadOrderResult().then(({ default: OrderResult }) => {
      ReactDOM.render(
        React.createElement(OrderResult, { store: store }),
        target
      );
    });
  }
}

export function initOrder() {
  renderOrderCheckout();
  initOrderCheckoutForm();
  renderOrderResult();
}
