import { getPrice } from "./utils";

function newCommonEventData() {
  return {
    ecommerce: {
      currencyCode: "RUB",
      impressions: []
    },
    event: "gtm-ee-event",
    "gtm-ee-event-category": "Enhanced Ecommerce",
    "gtm-ee-event-action": "Product Impressions",
    "gtm-ee-event-non-interaction": "True"
  };
}

export function pushNumbersDataLayer() {
  if (window.numbersEventData && window.numbersEventData.length) {
    let eventData = newCommonEventData();
    eventData.ecommerce.impressions = window.numbersEventData;
    window.dataLayer.push(eventData);
  }
}

export function pushTariffsDataLayer() {
  if (window.tariffsEventData && window.tariffsEventData.length) {
    let eventData = newCommonEventData();
    eventData.ecommerce.impressions = window.tariffsEventData;
    window.dataLayer.push(eventData);
  }
}

export function pushDevicesDataLayer() {
  if (window.devicesEventData && window.devicesEventData.length) {
    let eventData = newCommonEventData();
    eventData.ecommerce.impressions = window.devicesEventData;
    window.dataLayer.push(eventData);
  }
}

export function pushNumbersApiDataLayer(options) {
  let impressions = [],
    eventData = newCommonEventData();
  options.items.forEach(function(number, i, arr) {
    let price;
    if (window.IS_RENT_CATALOG) {
      price = number.rent_price;
    } else {
      if (number.has_discount) {
        price = number.exit_price;
      } else {
        price = number.site_price;
      }
    }
    impressions.push({
      name: number.number_digit,
      id: number.prefixed_id,
      price: getPrice(price),
      brand: number.provider_name,
      category: "Номер",
      list: options.list,
      position: i
    });
    eventData.ecommerce.impressions = impressions;
  });
  window.dataLayer.push(eventData);
}

export function pushTariffsApiDataLayer(options) {
  let impressions = [],
    eventData = newCommonEventData();
  options.items.forEach(function(tariff, i, arr) {
    impressions.push({
      name: tariff.name,
      id: tariff.prefixed_id,
      price: getPrice(tariff.current_price),
      brand: tariff.provider_name,
      category: "Тариф",
      list: options.list,
      position: i
    });
    eventData.ecommerce.impressions = impressions;
  });
  window.dataLayer.push(eventData);
}

export function pushDevicesApiDataLayer(options) {
  let impressions = [],
    eventData = newCommonEventData();
  options.items.forEach(function(device, i, arr) {
    impressions.push({
      name: device.name,
      id: device.prefixed_id,
      price: getPrice(device.current_price),
      brand: device.manufacturer_name,
      category: "Тариф",
      list: options.list,
      position: i
    });
    eventData.ecommerce.impressions = impressions;
  });
  window.dataLayer.push(eventData);
}

export default function initCommonEventData() {
  pushNumbersDataLayer();
  pushTariffsDataLayer();
  pushDevicesDataLayer();
}
