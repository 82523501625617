import { NAME_REGEXP, PHONE_REGEXP } from "./validation_methods";
import SuccessMessageFlatForm from "./components/flat-forms/SuccessMessageFlatForm";

export function initFeedbackForm() {
  let validator = {
    rules: {
      name: {
        required: true,
        name_regexp: NAME_REGEXP
      },
      email: {
        required: true,
        email: true
      },
      number: {
        required: true,
        regexp: PHONE_REGEXP
      }
    }
  };

  new SuccessMessageFlatForm(
    "#review_form",
    validator,
    "#review-modal-success"
  );
}
