import lodashTemplate from "lodash/template";
import forEach from "lodash/forEach";

import providerIcon from "../templates/common/provider_icon.ejs";
import reviewItem from "../templates/common/review_item.ejs";
import plainArticleItem from "../templates/common/plain_article_item.ejs";
import plainNewsItem from "../templates/common/plain_news_item.ejs";
import plainStockItem from "../templates/common/plain_stock_item.ejs";
import noResultsMessage from "../templates/common/no_results_message.ejs";

const preparedProviderIcon = lodashTemplate(providerIcon, {
  imports: {
    forEach: forEach,
    flavour: FLAVOUR
  }
});

export function renderProviderIcon(provider, options) {
  const defaultOptions = { small: false, cssClass: "" };
  options = Object.assign({}, defaultOptions, options);
  return preparedProviderIcon({
    provider: provider,
    small: options.small,
    cssClass: options.cssClass
  });
}

const preparedReviewItem = lodashTemplate(reviewItem, {
  imports: {
    forEach: forEach,
    flavour: FLAVOUR,
    renderReviewItem: renderReviewItem
  }
});

export function renderReviewItem(review) {
  return preparedReviewItem({ review: review });
}

const preparedPlainArticleItem = lodashTemplate(plainArticleItem, {
  imports: {
    forEach: forEach,
    flavour: FLAVOUR,
    renderProviderIcon: renderProviderIcon
  }
});

/**
 * Renders .plain-article-item CSS block.
 * @param {Object} article
 * @returns string
 */
export function renderPlainArticleItem(article) {
  return preparedPlainArticleItem({ article });
}

const preparedPlainNewsItem = lodashTemplate(plainNewsItem, {
  imports: {
    forEach: forEach,
    flavour: FLAVOUR,
    renderProviderIcon: renderProviderIcon
  }
});

export function renderPlainNewsItem(news) {
  return preparedPlainNewsItem({ news });
}

const preparedPlainStockItem = lodashTemplate(plainStockItem, {
  imports: {
    forEach: forEach,
    flavour: FLAVOUR,
    renderProviderIcon: renderProviderIcon
  }
});

export function renderPlainStockItem(stock) {
  let stock_image_width;
  let stock_image_height;
  let stock_image_detail;
  let stock_image_list;

  if (FLAVOUR === "desktop") {
    stock_image_width = stock.desktop_image_width;
    stock_image_height = stock.desktop_image_height;
    stock_image_detail = stock.desktop_image_detail;
    stock_image_list = stock.desktop_image_list;
  } else if (FLAVOUR === "tablet") {
    stock_image_width = stock.tablet_image_width;
    stock_image_height = stock.tablet_image_height;
    stock_image_detail = stock.tablet_image_detail;
    stock_image_list = stock.tablet_image_list;
  } else if (FLAVOUR === "mobile") {
    stock_image_width = 310;
    stock_image_height = 100;
    stock_image_detail = stock.mobile_image_detail;
    stock_image_list = stock.mobile_image_list;
  } else {
    stock_image_width = 310;
    stock_image_height = 100;
    stock_image_detail = stock.mobile_image_detail;
    stock_image_list = stock.mobile_image_list;
  }

  return preparedPlainStockItem({
    stock,
    stock_image_width,
    stock_image_height,
    stock_image_detail,
    stock_image_list
  });
}

const preparedNoResultsMessage = lodashTemplate(noResultsMessage, {
  imports: {
    forEach: forEach,
    flavour: FLAVOUR
  }
});

export function renderNoResultsMessage(message) {
  return preparedNoResultsMessage({ message: message || "Ничего не нашлось" });
}
