import { lazyLoadYandexShare, whenVisible } from "./lazy-load";

export function initYandexShareLazyLoad() {
  let elements = document.querySelectorAll(".ya-share2");
  if (elements.length > 0) {
    whenVisible(elements, async () => {
      const { default: initYandexShare } = await lazyLoadYandexShare();
      initYandexShare();
    });
  }
}
