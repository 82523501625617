import {hideAllHeaderDropdowns} from "./header-menu";
import {breakpointBelow, breakpointHeaderMobile} from "./breakpoints";

export function hideCartDropdown() {
  const cartButtons = Array.prototype.slice.apply(document.querySelectorAll(".cart-button"));
  cartButtons.forEach(btn => {
    btn.classList.remove("cart-button--active");
  });
}

export default function initCartButton() {
  $("body").on("click", ".cart-button__button", event => {
    hideAllHeaderDropdowns();
    $(event.target)
      .parents(".cart-button")
      .toggleClass("cart-button--active");

    const isTouch = window.matchMedia("(hover: none)").matches;
    const isPhone = breakpointBelow(breakpointHeaderMobile);
    if (isTouch && !isPhone) {
      // На этом девайсе нельзя навести курсор на пункт меню, меню будет открываться кликом.
      // Отключаем переход по ссылке по клику.
      // На смартфонах выпадающее меню не выводится.
      event.preventDefault();
    }
  });
}
