import debounce from "lodash/debounce";
import { getNews } from "../api";
import { renderNoResultsMessage, renderPlainNewsItem } from "./templates";

export function initNews() {
  let moreNewsButton = $("#more_news"),
    newsList = $("#news_list"),
    providerFilters = $("#provider_filters"),
    page = 2;

  moreNewsButton.click(function(e) {
    let params = providerFilters.serialize();

    moreNewsButton.text("Загрузка");

    getNews(params, page)
      .then(data => {
        moreNewsButton.text("Показать еще новости");
        if (data.next) {
          page += 1;
        } else {
          moreNewsButton.hide();
        }
        let html = data.results
          .map(function(news) {
            return renderPlainNewsItem(news);
          })
          .join("");
        newsList.append(html);
      })
      .fail(() => {
        moreNewsButton.text("Показать еще новости");
      });

    e.preventDefault();
  });

  const reloadList = debounce(function() {
    let params = providerFilters.serialize(),
      page = 1;

    getNews(params, page).then(data => {
      if (data.next) {
        page += 1;
        moreNewsButton.show();
      } else {
        moreNewsButton.hide();
      }
      let html;
      if (data.results.length > 0) {
        html = data.results
          .map(function(news) {
            return renderPlainNewsItem(news);
          })
          .join("");
      } else {
        html = renderNoResultsMessage("Ничего не найдено");
      }
      newsList.html(html);
    });
  }, 1000);
  providerFilters.on("change", reloadList);
}
