function initDataEvent() {
  if (window.deviceEventData) {
    window.dataLayer.push({
      ecommerce: {
        detail: {
          products: [window.deviceEventData]
        }
      },
      event: "gtm-ee-event",
      "gtm-ee-event-category": "Enhanced Ecommerce",
      "gtm-ee-event-action": "Product Clicks",
      "gtm-ee-event-non-interaction": "False"
    });
  }
}

export default function initDeviceDetail() {
  initDataEvent();
}
