import { getFeedbacks } from "../api";
import { renderReviewItem } from "./templates";
import { NAME_REGEXP, PHONE_REGEXP } from "./validation_methods";
import GenericFlatForm from "./components/flat-forms/GenericFlatForm";

export function initReview() {
  let moreReviewButton = $("#more_feedback"),
    reviewList = $("#feedback_list"),
    page = 2;

  moreReviewButton.click(function(e) {
    moreReviewButton.text("Загрузка");

    getFeedbacks(page)
      .then(data => {
        moreReviewButton.text("Показать еще отзывы");
        if (data.next) {
          page += 1;
        } else {
          moreReviewButton.hide();
        }

        let html = "";
        for (let i = 0; i < data.results.length; i++) {
          const review = data.results[i];
          html += renderReviewItem(review);
        }
        reviewList.append(html);
      })
      .fail(() => {
        moreReviewButton.text("Показать еще отзывы");
      });

    e.preventDefault();
  });

  let validator = {
    rules: {
      name: {
        required: true,
        name_regexp: NAME_REGEXP
      },
      email: {
        required: true,
        email: true
      },
      number: {
        required: true,
        regexp: PHONE_REGEXP
      }
    }
  };

  new GenericFlatForm("#feedback_form", validator);
}
