function initDataEvent() {
  if (window.tariffEventData) {
    dataLayer.push({
      ecommerce: {
        detail: {
          products: [window.tariffEventData]
        }
      },
      event: "gtm-ee-event",
      "gtm-ee-event-category": "Enhanced Ecommerce",
      "gtm-ee-event-action": "Product Clicks",
      "gtm-ee-event-non-interaction": "False"
    });
  }
}

export function initTariffDetail() {
  initDataEvent();
}
