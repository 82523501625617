import { postToApi } from "./api";
import { addButtonPreloader, removeButtonPreloader } from "./utils";

export class ClearTariffComparison {
  constructor(baseElement) {
    this.baseElement = baseElement;
    this.isLoading = false;
    this.initialBaseElementWidth = this.baseElement.style.width;

    this.endpoint = this.baseElement.getAttribute("data-endpoint");
    if (!this.endpoint) {
      console.warn(
        "clear-tariff-comparison is missing data-endpoint attribute",
        this.baseElement
      );
      return;
    }

    this.baseElement.addEventListener("click", event => {
      if (!this.isAdded) {
        event.preventDefault();
        this.clear();
      }
    });
  }

  startLoading() {
    this.isLoading = true;
    addButtonPreloader(this.baseElement);
  }

  stopLoading() {
    this.isLoading = false;
    removeButtonPreloader(this.baseElement);
  }

  clear() {
    if (this.isLoading) return;
    this.startLoading();

    postToApi(this.endpoint)
      .then(() => {
        location.reload();
      })
      .fail(() => {
        this.stopLoading();
      });
  }
}

export default function initClearTariffComparison() {
  document.querySelectorAll(".clear-tariff-comparison").forEach(element => {
    new ClearTariffComparison(element);
  });
}
