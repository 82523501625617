import { lazyLoadJqueryValidation, whenVisible } from "./lazy-load";
import { mapErrorsToForm, post, showSuccessFancybox } from "../modals";
import { addSpinnerOnFormBtn, removeSpinnerOnFormBtn } from "./utils";

const MODAL_ID = "#conversion-on-leaving-modal";
const DELAY = window.CONVERSION_ON_LEAVING_WINDOW_DELAY || 0;
const RETRY_DELAY = window.CONVERSION_ON_LEAVING_WINDOW_RETRY_DELAY || 0;

let globalState = {
  initAt: new Date(),
  lastClosedAt: null,
  isDisabled: false
};

const globalStateKey = {
  initAt: "conversion-on-leaving.globalState.initAt",
  lastClosedAt: "conversion-on-leaving.globalState.lastClosedAt",
  isDisabled: "conversion-on-leaving.globalState.isDisabled"
};

function loadDate(key, def) {
  let iso = localStorage.getItem(key);
  if (!iso) {
    return def;
  }

  let date = new Date(iso);
  // В случае невалидности iso.
  if (!(date instanceof Date) || isNaN(date)) {
    return def;
  }

  return date;
}

function saveDate(key, date) {
  if (date) {
    localStorage.setItem(key, date.toISOString());
  } else {
    localStorage.setItem(key, "");
  }
}

function loadGlobalState() {
  globalState.initAt = loadDate(globalStateKey.initAt, new Date());
  globalState.lastClosedAt = loadDate(globalStateKey.lastClosedAt, null);
  globalState.isDisabled =
    localStorage.getItem(globalStateKey.isDisabled) === "true";
}

function saveGlobalState() {
  saveDate(globalStateKey.initAt, globalState.initAt);
  saveDate(globalStateKey.lastClosedAt, globalState.lastClosedAt);
  localStorage.setItem(globalStateKey.isDisabled, globalState.isDisabled);
}

function secondsSince(date) {
  let now = new Date();
  return (now - date) / 1000;
}

function isModalShown() {
  return (
    $.fancybox.isActive &&
    $.fancybox.group[0] &&
    $.fancybox.group[0].element.attr("id") == MODAL_ID.slice(1)
  );
}

function showModal() {
  if (!isModalShown()) {
    $.fancybox.open(document.querySelector(MODAL_ID), {
      wrapCSS: "fancybox-subscription-modal",
      beforeClose: () => {
        if (isModalShown()) {
          globalState.lastClosedAt = new Date();
          saveGlobalState();
        }
      }
    });
  }
}

function handleLeaving() {
  if (globalState.isDisabled) return;

  if (globalState.lastClosedAt) {
    if (RETRY_DELAY < 0) return;

    if (secondsSince(globalState.lastClosedAt) > RETRY_DELAY) {
      showModal();
    }
  } else {
    if (secondsSince(globalState.initAt) > DELAY) {
      showModal();
    }
  }
}

export function disableConversionOnLeaving() {
  globalState.isDisabled = true;
  saveGlobalState();
}

function initForm() {
  let form = document.querySelector(".conversion-on-leaving__form");
  if (!form) {
    console.error("Форма окна возврата клиента при выходе с сайта не найдена.");
    return;
  }

  let action = form.getAttribute("action");
  let submitButton = form.querySelector(
    ".conversion-on-leaving__submit-button"
  );
  let submitButtonText = submitButton.getAttribute("data-original-text");
  if (!submitButtonText) {
    submitButtonText = submitButton.innerText;
    submitButton.setAttribute("data-original-text", submitButtonText);
  }

  let $form = $(form);
  let $submitButton = $(submitButton);

  whenVisible(form, async () => {
    await lazyLoadJqueryValidation();

    let validator = $form.validate({
      rules: {
        email: {
          required: true,
          email: true
        }
      }
    });

    form.addEventListener("submit", function(e) {
      e.preventDefault();

      if ($form.valid()) {
        let data = $form.serializeObject();

        addSpinnerOnFormBtn($submitButton, { fixWidth: true });
        post(action, data)
          .done(function() {
            $form.find("input, textarea").val("");
            removeSpinnerOnFormBtn($submitButton, submitButtonText, {
              unfixWidth: true
            });
            showSuccessFancybox("#conversion-on-leaving-success-modal");
            disableConversionOnLeaving();
          })
          .fail(function(response) {
            let errors = JSON.parse(response.responseText);
            mapErrorsToForm(errors, validator);
            removeSpinnerOnFormBtn($submitButton, submitButtonText, {
              unfixWidth: true
            });
          });
      }
    });
  });
}

export default function initConversionOnLeaving() {
  if (DELAY < 0) return;

  loadGlobalState();
  // Сразу пересохраняем на случай, если initAt был инициализирован.
  saveGlobalState();

  initForm();

  document.addEventListener("mouseleave", () => {
    handleLeaving();
  });

  document.addEventListener("visibilitychange", () => {
    if (document.visibilityState !== "visible") {
      handleLeaving();
    }
  });
}
