import Cookies from "js-cookie";
import {getStickyNavHeight} from "./header";

export class ComparisonTable {
  constructor(baseElement) {
    this.baseElement = baseElement;

    if (this.onlyDifferentCheckbox) {
      this.onlyDifferentCheckbox.addEventListener("change", () => {
        if (this.onlyDifferentCheckbox.checked) {
          this.showOnlyDifferentRows();
        } else {
          this.showAllRows();
        }
      });

      if (this.onlyDifferentCheckbox.checked) {
        this.showOnlyDifferentRows();
      }
    }

    this.itemRemoveButtons.forEach(button => {
      button.addEventListener("click", () => {
        this.removeItem(button);
      });
    });

    if (this.baseElement.classList.contains("comparison-table--sticky-top")) {
      this.initStickyTop();
    }
  }

  get onlyDifferentCheckbox() {
    let element = this.baseElement.querySelector(
      "[data-only-different-checkbox]"
    );
    return element;
  }

  get itemRemoveButtons() {
    return this.baseElement.querySelectorAll(
      ".comparison-table__item-remove-button"
    );
  }

  get rowGroups() {
    let groups = [];
    this.baseElement
      .querySelectorAll(".comparison-table__row-group")
      .forEach(groupElement => {
        let rows = [];
        groupElement
          .querySelectorAll(".comparison-table__row")
          .forEach(rowElement => {
            // Если атрибута нет - считаем по-умолчанию, что клетки разные
            let hasDifferentCells =
              !rowElement.hasAttribute("data-has-different-cells") ||
              rowElement.getAttribute("data-has-different-cells") == "True";

            rows.push({
              element: rowElement,
              hasDifferentCells
            });
          });

        groups.push({
          element: groupElement,
          rows
        });
      });
    return groups;
  }

  showOnlyDifferentRows() {
    this.rowGroups.forEach(group => {
      let shownRowsCount = 0;

      group.rows.forEach(row => {
        if (row.hasDifferentCells) {
          row.element.style.display = "";
          shownRowsCount++;
        } else {
          row.element.style.display = "none";
        }
      });

      if (shownRowsCount == 0) {
        group.element.style.display = "none";
      } else {
        group.element.style.display = "";
      }
    });

    this.setLastClasses();
  }

  showAllRows() {
    this.rowGroups.forEach(group => {
      group.rows.forEach(row => {
        row.element.style.display = "";
      });
      group.element.style.display = "";
    });

    this.setLastClasses();
  }

  /**
   * Добавляет класс comparison-table__row--last последний видимой строке в каждой группе
   * и класс comparison-table__row-group--last последней видимой группе.
   */
  setLastClasses() {
    // Удаляет классы --last со всех групп и строк
    this.rowGroups.forEach(group => {
      group.rows.forEach(row => {
        row.element.classList.remove("comparison-table__row--last");
      });
      group.element.classList.remove("comparison-table__row-group--last");
    });

    // Добавляет класс comparison-table__row--last последний видимой строке в каждой группе
    this.rowGroups.forEach(group => {
      let lastVisibleRow = group.rows
        .slice() // Копируем массив с помощью .slice(), потому что reverse() изменяет его на месте
        .reverse()
        .find(row => row.element.style.display != "none");
      if (lastVisibleRow) {
        lastVisibleRow.element.classList.add("comparison-table__row--last");
      }
    });

    // Добавляет класс comparison-table__row-group--last последней видимой группе
    let lastVisibleGroup = this.rowGroups
      .slice() // Копируем массив с помощью .slice(), потому что reverse() изменяет его на месте
      .reverse()
      .find(group => group.element.style.display != "none");
    if (lastVisibleGroup) {
      lastVisibleGroup.element.classList.add(
        "comparison-table__row-group--last"
      );
    }
  }

  removeItem(button) {
    let endpoint = button.getAttribute("data-endpoint");
    if (!endpoint) {
      console.warn(
        "comparison-table__item-remove-button is missing data-endpoint attribute",
        button
      );
      return;
    }

    let itemId = button.getAttribute("data-item-id");
    if (!itemId) {
      console.warn(
        "comparison-table__item-remove-button is missing data-item-id attribute",
        button
      );
      return;
    }

    button.classList.add("preloader", "preloader--active", "preloader--gray");

    $.ajax({
      method: "DELETE",
      url: endpoint,
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken")
      }
    })
      .done(() => {
        location.reload();
      })
      .always(() => {
        button.classList.remove(
          "preloader",
          "preloader--active",
          "preloader--gray"
        );
      });
  }

  initStickyTop() {
    // В реализации липкой шапки не используется "position: sticky", потому что
    // шапка находится как минимум в одном маленьком stacking context
    // (это обертка над таблицей, добавляющая горизонтальный скроллинг с помощью "overflow-x"),
    // и поэтому не прилипает к верхней границе окна.

    // Jquery.sticky и другие библиотеки, работающие на "position: fixed", не используются,
    // потому что с "position: fixed" шапка не реагирует на горизонтальный скроллинг таблицы.

    let topMargin = getStickyNavHeight();
    let stickyTarget = this.baseElement.querySelector(
      ".comparison-table__header-row"
    );
    let stickyContainer = this.baseElement;
    let stickyUntil = this.baseElement.querySelector(
      ".comparison-table__footer-row"
    );

    let currentTranslateY = 0;
    let updateTargetPosition = () => {
      let containerRect = stickyContainer.getBoundingClientRect();
      let targetRect = stickyTarget.getBoundingClientRect();
      let untilRect = stickyUntil.getBoundingClientRect();

      let targetY = topMargin;

      if (containerRect.y > topMargin) {
        targetY = containerRect.y;
      }

      if (targetY + targetRect.height > untilRect.y) {
        targetY = untilRect.y - targetRect.height;
      }

      currentTranslateY += targetY - targetRect.y;
      stickyTarget.style.transform = `translateY(${currentTranslateY}px)`;
    };

    let frameFunction = () => {
      updateTargetPosition();
      window.requestAnimationFrame(frameFunction);
    };
    window.requestAnimationFrame(frameFunction);
  }
}

export default function initComparisonTable() {
  document.querySelectorAll(".comparison-table").forEach(element => {
    new ComparisonTable(element);
  });
}
