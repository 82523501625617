export default function initCheckboxIconItem() {
  document.addEventListener("change", event => {
    let target = event.target;

    let isCheckbox =
      target.tagName === "INPUT" && target.getAttribute("type") === "checkbox";
    if (!isCheckbox) return;

    let block = target.closest(".checkbox-icon-item");
    if (!block) return;

    let iconElement = block.querySelector(".checkbox-icon-item__icon");
    if (!iconElement) return;

    if (target.checked) {
      iconElement.classList.add("checkbox-icon-item__icon--active");
    } else {
      iconElement.classList.remove("checkbox-icon-item__icon--active");
    }
  });
}
