import React from "react";
import PropTypes from "prop-types";

import { chunk } from "../../../utils";

class FullSubdomainsList extends React.Component {
  render() {
    let { subdomains, columnsCount } = this.props,
      rowCount = Math.ceil(subdomains.length / columnsCount),
      columns = chunk(subdomains, rowCount);

    return (
      <div className="region-selector__full-list">
        {columns.map(this.createColumn.bind(this))}
      </div>
    );
  }

  createColumn(column) {
    return (
      <div key={column[0].letter} className="region-selector__full-list-column">
        {column.map(this.createListByLetter.bind(this))}
      </div>
    );
  }

  createListByLetter(item) {
    return (
      <div key={item.letter} className="region-selector__letter-list">
        <div className="region-selector__letter-list-letter">{item.letter}</div>
        {item.subdomains.map(this.createListItem)}
      </div>
    );
  }

  createListItem(subdomain) {
    return (
      <a
        key={subdomain.id}
        href={subdomain.change_url}
        className="region-selector__full-list-link"
      >
        {subdomain.display_name}
      </a>
    );
  }
}

FullSubdomainsList.propTypes = {
  columnsCount: PropTypes.number,
  subdomains: PropTypes.array
};

export default FullSubdomainsList;
