import {NAME_REGEXP, PHONE_REGEXP} from "./validation_methods";
import {initPhoneMask} from "./masks";
import debounce from "lodash/debounce";
import {addSpinnerOnFormBtn, escapeHTML, prepareFormErrors, prettyNum, removeSpinnerOnFormBtn} from "./utils";
import Cookies from "js-cookie";
import {mapErrorsToForm, showSuccessFancybox} from "../modals";
import {UPDATE_CHECKBOX_HORIZONTAL_ITEM_EVENT} from "./checkbox-horizontal-item";
import {lazyLoadJqueryValidation} from "./lazy-load";
import {disableConversionOnLeaving} from "./conversion-on-leaving";
import {createNiceSelect} from "./nice-select";

const PERIOD_MONTH = 1;
const PERIOD_YEAR = 2;

class SellNumberFormItem {
  constructor(baseElement, index, onDelete, placementPriceUrl) {
    this.baseElement = baseElement;
    this.onDelete = onDelete;

    this.numberInput = this.baseElement.find(".sell-number-form__number-input");
    this.priceInput = this.baseElement.find(".sell-number-form__price-input");
    this.providerSelect = this.baseElement.find(
      ".sell-number-form__provider-select"
    );
    this.subdomainSelect = this.baseElement.find(
      ".sell-number-form__subdomain-select"
    );
    this.deleteButton = this.baseElement.find(
      ".sell-number-form__delete-item-button"
    );

    const placementPriceElement = this.baseElement.find(
      ".sell-number-form__placement-price"
    );
    this.placementPriceField = new PlacementPriceField(
      placementPriceElement,
      placementPriceUrl
    );

    this.setIndex(index);

    initPhoneMask(this.numberInput);
    createNiceSelect(this.providerSelect[0]);
    createNiceSelect(this.subdomainSelect[0]);
    this.initValidation();

    this.deleteButton.on("click", () => {
      this.deleteItem();
    });

    this.priceInput.on("input", () => {
      this.placementPriceField.setPrice(this.getPrice());
    });
  }

  async initValidation() {
    await lazyLoadJqueryValidation();

    this.numberInput.rules("add", {
      required: true,
      regexp: PHONE_REGEXP
    });
    this.priceInput.rules("add", {
      required: true
    });
    this.providerSelect.rules("add", {
      required: true
    });
    this.subdomainSelect.rules("add", {
      required: true
    });
  }

  setIndex(index) {
    this.setInputName(this.numberInput, `number_set-${index}-number`);
    this.setInputName(this.priceInput, `number_set-${index}-price`);
    this.setInputName(this.providerSelect, `number_set-${index}-provider`);
    this.setInputName(this.subdomainSelect, `number_set-${index}-subdomain`);
    this.index = index;
  }

  setInputName(element, name) {
    const oldName = element.attr("name");
    const errorLabel = this.baseElement.find(`label.error[for="${oldName}"]`);
    errorLabel.attr("for", name);
    errorLabel.attr("id", `${name}-error`);

    element.attr("name", name);
  }

  setPeriod(period) {
    this.placementPriceField.setPeriod(period);
  }

  getPrice() {
    const priceStr = this.priceInput.val();
    if (!priceStr) {
      return null;
    }

    const price = Number(priceStr);
    if (Number.isNaN(price)) {
      return null;
    }

    return price;
  }

  deleteItem() {
    this.onDelete(this);
    this.baseElement.remove();
  }

  showDeleteButton() {
    this.deleteButton.css("display", "");
  }

  hideDeleteButton() {
    this.deleteButton.css("display", "none");
  }
}

class PlacementPriceField {
  constructor(baseElement, reloadUrl) {
    this.baseElement = baseElement;
    this.reloadUrl = reloadUrl;
    this.price = null;
    this.isErrored = false;
    this.period = null;
    this.monthly = null;
    this.yearly = null;
    this.reloadDebounced = debounce(() => this.reload(), 1000);

    this.render();
    this.stopLoading();
  }

  setPeriod(period) {
    this.period = period;
    this.render();
  }

  setPrice(price) {
    this.price = price;
    this.scheduleReload();
  }

  scheduleReload() {
    if (this.price === null) {
      this.reload();
    } else {
      this.startLoading();
      this.reloadDebounced();
    }
  }

  async reload() {
    this.isErrored = false;

    try {
      if (this.price === null) {
        return;
      }

      const query = new URLSearchParams();
      query.append("price", this.price);

      const url = `${this.reloadUrl}?${query.toString()}`;

      const resp = await fetch(url);
      if (resp.status !== 200) {
        throw new Error(`Placement price HTTP ${resp.status}`);
      }

      const respJson = await resp.json();
      const monthly = Number(respJson.monthly);
      if (Number.isNaN(monthly)) {
        throw new Error(`Invalid placement price ${respJson.monthly}`);
      }
      const yearly = Number(respJson.yearly);
      if (Number.isNaN(yearly)) {
        throw new Error(`Invalid placement price ${respJson.yearly}`);
      }

      this.monthly = monthly;
      this.yearly = yearly;
    } catch (e) {
      console.error(e);
      this.isErrored = true;
    } finally {
      this.render();
      this.stopLoading();
    }
  }

  render() {
    if (this.isErrored) {
      this.baseElement.html(mutedHTML("Ошибка"));
      return;
    }

    if (this.price === null) {
      this.baseElement.html(mutedHTML("Стоимость размещения"));
      return;
    }

    let monthlyHTML = escapeHTML(prettyNum(this.monthly));
    monthlyHTML = `${monthlyHTML}${CURRENCY.site_symbol}/мес`;
    monthlyHTML = nowrapHTML(monthlyHTML);
    if (this.period !== PERIOD_MONTH) {
      monthlyHTML = mutedHTML(monthlyHTML);
    }

    let yearlyHTML = escapeHTML(prettyNum(this.yearly));
    yearlyHTML = `${yearlyHTML}${CURRENCY.site_symbol}/год`;
    yearlyHTML = nowrapHTML(yearlyHTML);
    if (this.period !== PERIOD_YEAR) {
      yearlyHTML = mutedHTML(yearlyHTML);
    }

    const html = `${monthlyHTML} ${mutedHTML("или")} ${yearlyHTML}`;
    this.baseElement.html(html);
  }

  startLoading() {
    this.baseElement.addClass("skeleton");
  }

  stopLoading() {
    this.baseElement.removeClass("skeleton");
  }
}

const formValidatorConfig = {
  rules: {
    name: {
      required: true,
      name_regexp: NAME_REGEXP
    },
    email: {
      required: true,
      email: true
    },
    additional_info: {
      required: false
    },
    number: {
      required: true,
      regexp: PHONE_REGEXP
    }
  }
}

class SellNumberForm {
  constructor(htmlElement) {
    this.baseElement = $(htmlElement);
    this.url = this.baseElement.attr("action");
    this.button = this.baseElement.find(".button");
    this.buttonText = this.button.text().trim();
    // Вызывать до инициализации items, чтобы валидатор на форме создался до правил валидации на items.
    this.initValidation();

    this.nonFieldErrorContainer = this.baseElement.find(".sell-number-form__non-field-errors")
    this.placementPriceUrl = this.baseElement.attr("data-placement-price-url");
    this.itemTemplate = this.baseElement.find(
      ".sell-number-form__item-template"
    );
    this.addItemButton = this.baseElement.find(
      ".sell-number-form__add-item-button"
    );
    this.itemCountInput = this.baseElement.find(
      ".sell-number-form__item-count-input"
    );
    this.itemContainer = this.baseElement.find(
      ".sell-number-form__item-container"
    );
    this.itemTemplate = this.baseElement
      .find(".sell-number-form__item")
      .first()
      .clone();
    this.items = this.baseElement
      .find(".sell-number-form__item")
      .toArray()
      .map(
        (el, ix) =>
          new SellNumberFormItem(
            $(el),
            ix,
            this.deleteNumberItem.bind(this),
            this.placementPriceUrl
          )
      );

    this.baseElement.on("submit", e => {
      e.preventDefault();
      this.submit()
    });

    this.addItemButton.on("click", () => {
      this.addNumberItem();
    });

    this.baseElement.find("[name=type]").on("change", () => {
      this.updateItemPeriods();
    });
    this.updateItemPeriods();
  }

  async initValidation() {
    await lazyLoadJqueryValidation();
    this.validator = this.baseElement.validate(formValidatorConfig);
  }

  get validatorHasInit() {
    return !!this.validator;
  }

  submit() {
    if (!this.validatorHasInit) return;
    if (!this.baseElement.valid()) return;

    let data = new FormData(this.baseElement[0]);
    addSpinnerOnFormBtn(this.button);

    $.ajax({
      url: this.url,
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken")
      },
      method: "POST",
      processData: false,
      contentType: false,
      dataType: "json",
      data: data
    })
    .done(response => this.doneRequest(response))
    .fail(response => this.failRequest(response));
  }

  doneRequest(response) {
    if (response.status === "ok") {
      this.resetForm();
      this.baseElement
        .find(".checkbox-horizontal-item")
        .trigger(UPDATE_CHECKBOX_HORIZONTAL_ITEM_EVENT);
      removeSpinnerOnFormBtn(this.button, this.buttonText);
      disableConversionOnLeaving();
      window.location.href = response.payment_url;
    } else {
      let errors = this.mapNonFieldErrors(response.errors);
      errors = prepareFormErrors(errors);
      mapErrorsToForm(errors, this.validator);
      removeSpinnerOnFormBtn(this.button, this.buttonText);
    }
  }

  mapNonFieldErrors(errors) {
    this.nonFieldErrorContainer.css("display", "none");
    errors = {...errors};

    const nonFieldErrors = errors["__all__"] || [];
    delete errors["__all__"];
    const messages = nonFieldErrors.map(err => err.message ? err.message.toString() : err.toString());
    if (messages.length === 0) return errors;

    const ul = document.createElement("ul");
    messages.forEach(msg => {
      const li = document.createElement("li");
      li.innerText = msg;
      ul.appendChild(li);
    });

    const preMsg = document.createElement("p");
    preMsg.innerText = "Пожалуйста, исправьте ошибки, чтобы мы приняли ваш запрос:";
    preMsg.classList.add("mb-8");

    this.nonFieldErrorContainer.html("");
    this.nonFieldErrorContainer.append(preMsg);
    this.nonFieldErrorContainer.append(ul);
    this.nonFieldErrorContainer.css("display", "");

    return errors;
  }

  failRequest(response) {
    console.log(response);
  }

  addNumberItem() {
    const newItemElement = this.itemTemplate.clone();
    newItemElement.appendTo(this.itemContainer);

    const newItem = new SellNumberFormItem(
      newItemElement,
      this.items.length,
      this.deleteNumberItem.bind(this),
      this.placementPriceUrl
    );
    newItem.setPeriod(this.getTypePeriod());
    this.items.push(newItem);

    this.itemCountInput.val(this.items.length);
    this.updateDeleteButtons();
  }

  deleteNumberItem(item) {
    this.items = this.items
      .slice(0, item.index)
      .concat(this.items.slice(item.index + 1));

    this.items.forEach((item, index) => {
      item.setIndex(index);
    });

    this.itemCountInput.val(this.items.length);
    this.updateDeleteButtons();
  }

  updateDeleteButtons() {
    if (this.items.length === 1) {
      this.items[0].hideDeleteButton();
    } else {
      this.items.forEach(item => item.showDeleteButton());
    }
  }

  getTypePeriod() {
    const typeInput = this.baseElement.find("[name=type]:checked");
    if (!typeInput.length) {
      return null;
    }

    const period = Number(typeInput.attr("data-period"));
    if (Number.isNaN(period)) {
      return null;
    } else {
      return period;
    }
  }

  updateItemPeriods() {
    const period = this.getTypePeriod();
    for (const item of this.items) {
      item.setPeriod(period);
    }
  }

  resetForm() {
    this.baseElement[0].reset();
    this.items.slice(1).forEach(item => item.deleteItem());
  }
}

export function initSellNumber() {
  let forms = document.querySelectorAll(".sell-number-form");
  for (let i = 0; i < forms.length; i++) {
    const form = forms[i];
    new SellNumberForm(form);
  }
}

function mutedHTML(html) {
  return `<span class="text-muted">${html}</span>`;
}

function nowrapHTML(html) {
  return `<span class="text-nowrap">${html}</span>`;
}
