import Cookies from "js-cookie";

export function post(url, data) {
  return $.ajax({
    url: url,
    headers: {
      "X-CSRFToken": Cookies.get("csrftoken")
    },
    contentType: "application/json; charset=UTF-8",
    dataType: "json",
    method: "POST",
    data: JSON.stringify(data)
  });
}

export function showSuccessFancybox(el = "#show-success") {
  $.fancybox.open($(el));
}

export function mapErrorsToForm(errors, $validator) {
  $validator.showErrors(errors);
}
