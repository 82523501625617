import getClearableInputs from "./utils";

function initCatalogFilterGroup(element) {
  const clearButton = element.querySelector(".catalog-filter-group__label-clear");
  if (clearButton) {
    clearButton.addEventListener("click", () => {
      clearGroup(element);
    });
  }

  const update = () => {
    if (isGroupClear(element)) {
      element.classList.remove("catalog-filter-group--filled");
    } else {
      element.classList.add("catalog-filter-group--filled");
    }
  };
  element.addEventListener("change", update);
  element.addEventListener("input", update);
  update();
}

function isGroupClear(element) {
  for (const field of getClearableInputs(element)) {
    if (!field.isClear()) {
      return false;
    }
  }
  return true;
}

function clearGroup(element) {
  for (const field of getClearableInputs(element)) {
    if (!field.isClear()) {
      field.clear();
      field.input.dispatchEvent(new Event("change", {bubbles: true}));
    }
  }
}

export default function initCatalogFilterGroupModule() {
  const elements = document.querySelectorAll(".catalog-filter-group");
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    initCatalogFilterGroup(element);
  }
}
