import {override} from "../core";

export default function loadMore(view) {
  view.buttonMore = view.element.querySelector(".js-catalog-view__button-more");
  if (!view.buttonMore) {
    return view;
  }

  view.currentPage = 1;
  view.hasMore = true;
  view.setHasMore = hasMore => {
    view.hasMore = hasMore;
    if (hasMore) {
      view.buttonMore.style.display = "";
    } else {
      view.buttonMore.style.display = "none";
    }
  }

  view.setLoading = override(view.setLoading, (callSuper, loading) => {
    callSuper(loading);

    if (loading) {
      view.buttonMore.classList.add("preloader--active");
    } else {
      view.buttonMore.classList.remove("preloader--active");
    }
  })

  view.reload = override(view.reload, async (callSuper, extraParams) => {
    view.currentPage = 1;
    const resp = await callSuper(extraParams);
    view.setHasMore(!!resp.next);
    return resp;
  })

  view.loadMore = async () => {
    const extraParams = new URLSearchParams();
    extraParams.set("page", view.currentPage + 1);

    try {
      const resp = await view.loadAppend(extraParams);
      // Инкрементируем только после успешной загрузки.
      view.currentPage += 1;
      view.setHasMore(!!resp.next);
    } catch (e) {
      if (e.name === "CatalogHttpError" && e.status === 404) {
        view.setHasMore(false);
      }

      throw e;
    }
  }

  view.buttonMore.addEventListener("click", () => {
    if (view.isLoading) return;
    view.loadMore();
  });

  return view;
}
