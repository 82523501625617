import { initSentry } from "../common/sentry";
initSentry();

import "core-js/features/url";
import "core-js/features/dom-collections/for-each";
import "core-js/features/object/assign";
import "core-js/features/promise";
import "core-js/features/weak-set";

import jQuery from "jquery";
// export for others scripts to use
window.$ = window.jQuery = jQuery;
// require("expose-loader?$!jquery");
// require("expose-loader?jQuery!jquery");
require("jquery-countdown");
window.fancybox = require("fancybox")($);
require("perfect-scrollbar/jquery")($);
require("jquery-mask-plugin");
require("jquery-sticky");
require("form-serializer");
import "suggestions-jquery";

import toastr from "toastr";
toastr.options.escapeHtml = true;
toastr.options.closeButton = true;
toastr.options.positionClass = "toast-top-center";

import "../common/seohide";

import "../styles/topnomer/desktop.scss";
import "tooltipster/dist/css/tooltipster.bundle.min.css";
import "tooltipster/dist/css/plugins/tooltipster/sideTip/themes/tooltipster-sideTip-borderless.min.css";
import "swiper/swiper-bundle.css";

import { initNumberSelectionModal } from "../common/number_selection_modal";
import { initTariffSelectionModal } from "../common/tariff_selection_modal";
import initDatepicker from "../common/datepicker";
import { initCallBackModal } from "../common/call_back_modal";
import { initCountdownTimers } from "../common/countdown_timer";
import { initArticles } from "../common/articles";
import { initContacts } from "../common/contacts";
import { initCooperation } from "../common/cooperation";
import { initSellNumber } from "../common/sell-number";
import { initFeedbackForm } from "../common/feedback";
import { initNews } from "../common/news";
import { initReview } from "../common/reviews";
import { initStocks } from "../common/stocks";
import { initNumbersPage } from "../desktop/numbers_page";
import { initTariffDetail } from "../desktop/tariff_detail";
import { initErrorPages } from "../desktop/error_pages";
import { initScripts } from "../desktop/scripts";
import { initCart } from "../common/react/modules/cart";
import { initOrder } from "../desktop/react/modules/order";
import initDeviceDetail from "../desktop/device_detail";
import initSerializeJsonPlugin from "../common/serialize-to-json";
import initCommonEventData from "../common/data_events";
import initTooltipster from "../common/tooltipster";
import { initSubdomains } from "../common/subdomains";
import { initPhoneHighlight } from "../common/dom";
import { initBuyNumberOrderForm } from "../common/order_number_form";
import GenericSearcher from "../common/components/searchers/GenericSearcher";
import ShortLinkGenerator from "../common/components/ShortLinkGenerator";
import initCompactNumbersFilter from "../common/compact-numbers-filter";
import initCertificateSlider from "../common/certificate-slider";
import initAuthorArticles from "../common/author-articles";
import initAddToTariffComparison from "../common/add-to-tariff-comparison";
import initClearTariffComparison from "../common/clear-tariff-comparison";
import initDragToScroll from "../common/drag-to-scroll";
import initComparisonTable from "../common/comparison-table";
import initScrollHint from "../common/scroll-hint";
import initPillCheckbox from "../common/pill-checkbox";
import initInfoSlider from "../common/info-slider";
import initMenuCollapse from "../common/menu-collapse";
import initProviderCoverageMap from "../common/provider-coverage-map";
import initProductGallery from "../common/product-gallery";
import initCheckboxIconItem from "../common/checkbox-icon-item";
import initMainSlider from "../common/main-slider";
import initStockRequest from "../common/stock-request";
import initAttachmentCard from "../common/attachment-card";
import initTabbed from "../common/tabbed";
import initPaymentButton from "../common/payment-button";
import initPaymentButtonsContainer from "../common/payment-buttons-container";
import initGalleryWithThumbs from "../common/gallery-with-thumbs";
import initToggler from "../common/toggler";
import initCheckboxHorizontalItem from "../common/checkbox-horizontal-item";
import initLargePicker from "../common/large-picker";
import initSliderAuto from "../common/slider-auto";
import { lazyLoadJqueryValidation, whenVisible } from "../common/lazy-load";
import { initYandexShareLazyLoad } from "../common/yandex-share-lazy-load";
import initGTM from "../common/gtm";
import initPromocodeQueryLoader from "../common/promocode-query-loader";
import initConversionOnLeaving from "../common/conversion-on-leaving";
import initEmailSuggestions from "../common/email-suggestions";
import initSuccessModal from "../common/success-modal";
import initFooterDisclaimer from "../common/footer-disclaimer";
import initNiceSelect from "../common/nice-select";
import initCheckboxSelectorAny from "../common/checkbox-selector-any";
import initRangeInputGroup from "../common/range-input-group";
import initCallsDirectionSlider from "../common/calls-direction-slider";
import initInternetRangeInputGroup from "../common/internet-range-input-group";
import initTariffCalculator from "../common/tariff-calculator";
import initScrollToTop from "../common/scroll-to-top";
import initScrollToEnd from "../common/init-scroll-to-end";
import initInfoAccordion from "../common/info-accordion";
import initCartButton from "../common/cart-button";
import initInfoAccordionToTabbed from "../common/info-accordion-to-tabbed";
import initHeaderMenu from "../common/header-menu";
import initHeader from "../common/header";
import initHeaderSearchBar from "../common/header-search-bar";
import initFooterMenu from "../common/footer-menu";
import {initCatalogViewNumbersCatalog, initCatalogViewNumbersListing} from "../common/catalog-view/numbers";
import initSidebarDrawerCard from "../common/sidebar-drawer-card";
import initCatalogFilterGroupModule from "../common/catalog-filter-group";
import initCatalogFilterGroupCounterModule from "../common/catalog-filter-group-counter";
import initCatalogViewNumberSetCatalog from "../common/catalog-view/number-sets";
import initCheckboxSelectorExclusiveModule from "../common/checkbox-selector-exclusive";
import initCardSlider from "../common/card-slider";
import initMasksModule from "../common/masks";
import initQuickFillModule from "../common/quick-fill";
import {initCatalogViewTariffsCatalog, initCatalogViewTariffsListing} from "../common/catalog-view/tariffs";
import initTariffsConditionsModule from "../common/tariffs-conditions";
import {initCatalogViewDevicesCatalog, initCatalogViewDevicesLanding} from "../common/catalog-view/devices";
import {initCatalogViewStarNumbersCatalog, initCatalogViewStarNumbersIndex} from "../common/catalog-view/star-numbers";
import initInternetReasonsModule from "../common/internet-reasons";
import initAutoMdSwiperModule from "../common/auto-md-swiper";
import initShadeSwiperModule from "../common/shade-swiper";
import initUnderlineIconButton from "../common/underline-icon-button";

// Инициализация библиотек
initSerializeJsonPlugin($);
initYandexShareLazyLoad();
initGTM();

$(function() {
  initNumberSelectionModal();
  initTariffSelectionModal();
  initCallBackModal();
  initCountdownTimers();
  initArticles();
  initContacts();
  initCooperation();
  initSellNumber();
  initFeedbackForm();
  initNews();
  initReview();
  initStocks();
  initNumbersPage();
  initDeviceDetail();
  initTariffDetail();
  initErrorPages();
  initScripts();
  initDatepicker();
  initCart();
  initOrder();
  initCommonEventData();
  new GenericSearcher($("#search-form-page"));
  initTooltipster();
  initSubdomains();
  initPhoneHighlight();
  initBuyNumberOrderForm();
  initCompactNumbersFilter();
  initCertificateSlider();
  initAuthorArticles();
  initAddToTariffComparison();
  initClearTariffComparison();
  initDragToScroll();
  initComparisonTable();
  initScrollHint();
  initPillCheckbox();
  initInfoSlider();
  initMenuCollapse();
  initProviderCoverageMap();
  initProductGallery();
  initCheckboxIconItem();
  initMainSlider();
  initStockRequest();
  initAttachmentCard();
  initTabbed();
  initPaymentButton();
  initPaymentButtonsContainer();
  initGalleryWithThumbs();
  initToggler();
  initCheckboxHorizontalItem();
  initLargePicker();
  initSliderAuto();
  initPromocodeQueryLoader();
  initConversionOnLeaving();
  initEmailSuggestions();
  initSuccessModal();
  initFooterDisclaimer();
  initNiceSelect();
  initCheckboxSelectorAny();
  initRangeInputGroup();
  initCallsDirectionSlider();
  initInternetRangeInputGroup();
  initTariffCalculator();
  initScrollToTop();
  initScrollToEnd();
  initInfoAccordion();
  initCartButton();
  initInfoAccordionToTabbed();
  initHeaderMenu();
  initHeader();
  initHeaderSearchBar();
  initFooterMenu();
  initCatalogViewNumbersCatalog();
  initCatalogViewNumberSetCatalog();
  initSidebarDrawerCard();
  initCatalogFilterGroupModule();
  initCatalogFilterGroupCounterModule();
  initCheckboxSelectorExclusiveModule();
  initCardSlider();
  initMasksModule();
  initQuickFillModule();
  initCatalogViewTariffsCatalog();
  initTariffsConditionsModule();
  initCatalogViewDevicesCatalog();
  initCatalogViewDevicesLanding();
  initCatalogViewStarNumbersCatalog();
  initCatalogViewStarNumbersIndex();
  initInternetReasonsModule();
  initAutoMdSwiperModule();
  initShadeSwiperModule();
  initUnderlineIconButton();
  initCatalogViewNumbersListing();
  initCatalogViewTariffsListing();

  whenVisible($(".form"), async () => {
    await lazyLoadJqueryValidation();
    $(".form").validate();
  });

  $(".short-link-generator").each((index, htmlElement) => {
    new ShortLinkGenerator($(htmlElement));
  });
});
