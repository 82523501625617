import Cookies from "js-cookie";

export function getFromApi(url, data) {
  return $.ajax({
    url: url,
    contentType: "application/json; charset=UTF-8",
    data: data,
    headers: {
      "X-CSRFToken": Cookies.get("csrftoken")
    },
    dataType: "json",
    method: "GET"
  });
}

export function postToApi(url, data) {
  return $.ajax({
    url: url,
    contentType: "application/json; charset=UTF-8",
    data: JSON.stringify(data),
    headers: {
      "X-CSRFToken": Cookies.get("csrftoken")
    },
    dataType: "json",
    method: "POST"
  });
}

export function putToApi(url, data) {
  return $.ajax({
    url: url,
    contentType: "application/json; charset=UTF-8",
    data: JSON.stringify(data),
    headers: {
      "X-CSRFToken": Cookies.get("csrftoken")
    },
    dataType: "json",
    method: "PUT"
  });
}

export function deleteFromApi(url, data) {
  return $.ajax({
    url: url,
    contentType: "application/json; charset=UTF-8",
    data: JSON.stringify(data),
    headers: {
      "X-CSRFToken": Cookies.get("csrftoken")
    },
    dataType: "json",
    method: "DELETE"
  });
}

/**
 * Очередь AJAX-запросов JQuery. Позволяет выполнять запросы последовательно.
 * Пример:
 *
 *   let queue = new AjaxQueue();
 *   queue.push(() => $.ajax({...}));
 *   queue.push(() => $.ajax({...}));
 *
 * push возвращает объект Deferred, на котором можно вызывать методы done/fail/always/then
 * так же, как если бы $.ajax был вызван напрямую:
 *
 *   queue
 *     .push(() => $.ajax({...}))
 *     .done(data => {
 *       console.log(data);
 *     })
 */
export class AjaxQueue {
  constructor() {
    this.lastDeferred = null;
  }

  push(callback) {
    // Инициализируем lastDeferred лениво, потому что на момент вызова конструктора
    // переменная $ может быть еще не объявлена.
    if (!this.lastDeferred) {
      this.lastDeferred = $.Deferred().resolve();
    }

    let newDeferred = $.Deferred();
    let run = () => {
      let xhr = callback();
      xhr.then(newDeferred.resolve, newDeferred.reject);
      return xhr;
    };
    this.lastDeferred = this.lastDeferred.then(run, run);
    return newDeferred;
  }
}
