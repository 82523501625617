export const SUBDOMAIN_LIST_LOAD = "SUBDOMAIN_LIST_LOAD";
export const SUBDOMAIN_FILTER_OUT = "SUBDOMAIN_FILTER_OUT";
export const SUBDOMAIN_TOGGLE_FULL_LIST = "SUBDOMAIN_TOGGLE_FULL_LIST";
export const SUBDOMAIN_TOGGLE_SHORT_LIST = "SUBDOMAIN_TOGGLE_SHORT_LIST";
import { getSubdomainsByLetter } from "../../../common/utils";

const initialState = {
  allSubdomains: [],
  shortListSubdomains: [],
  filterSubdomainsByLetter: [],
  columnsCount: 0,
  rowsCount: 0,
  isOpenFullList: false,
  isOpenShortList: true
};

export const subdomainReducer = function(state = initialState, action) {
  switch (action.type) {
    case SUBDOMAIN_LIST_LOAD:
      return Object.assign({}, state, {
        allSubdomains: action.allSubdomains,
        shortListSubdomains: action.shortListSubdomains,
        filterSubdomainsByLetter: action.filterSubdomainsByLetter,
        columnsCount: action.columnsCount,
        rowsCount: action.rowsCount
      });

    case SUBDOMAIN_FILTER_OUT:
      let filterValue = action.filterValue,
        filterSubdomains = state.allSubdomains.filter(
          subdomain =>
            subdomain.display_name
              .toLowerCase()
              .indexOf(filterValue.toLowerCase()) != -1
        ),
        filterSubdomainsByLetter = getSubdomainsByLetter(filterSubdomains),
        isOpenShortList = filterValue == "",
        isOpenFullList = !isOpenShortList;

      return Object.assign({}, state, {
        filterSubdomainsByLetter: filterSubdomainsByLetter,
        isOpenShortList: isOpenShortList,
        isOpenFullList: isOpenFullList
      });

    case SUBDOMAIN_TOGGLE_FULL_LIST:
      return Object.assign({}, state, {
        isOpenFullList: !state.isOpenFullList
      });

    case SUBDOMAIN_TOGGLE_SHORT_LIST:
      return Object.assign({}, state, {
        isOpenShortList: !state.isOpenShortList
      });
  }
  return state;
};
