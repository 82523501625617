import { getNumbersList } from "../api";
import { renderNumbersList } from "./templates";

export function initErrorPages() {
  let moreNewsButton = $("#more_numbers"),
    numbersList = $("#numbers_list"),
    catalogId = numbersList.attr("data-catalog-id"),
    pageSize = numbersList.data("page-size"),
    content = numbersList.data("content"),
    params =
      "catalog_id=" + catalogId + "&page_size=" + pageSize + "&mask=" + content,
    page = 2;

  moreNewsButton.click(function(e) {
    moreNewsButton.text("Загрузка");

    getNumbersList(params, page, pageSize)
      .then(data => {
        moreNewsButton.text("Показать еще");
        if (data.next) {
          page += 1;
        } else {
          moreNewsButton.hide();
        }
        let html = renderNumbersList(data.results);
        numbersList.append(html);
      })
      .fail(() => {
        moreNewsButton.text("Показать еще");
      });

    e.preventDefault();
  });
}
