const NUMBERS_LIST = "/api/numbers/numbers-catalog/";
const STAR_NUMBERS_LIST = "/api/star-numbers-catalog/";
const NUMBER_SET_LIST = "/api/number-set-catalog/";
const TARIFFS_LIST = "/api/tariffs/tariffs-catalog/";
const DEVICES_LIST = "/api/devices-catalog/";
const SIMILAR_NUMBERS_LIST = "/api/numbers/similar-numbers/";
const SIMILAR_TARIFFS_LIST = "/api/tariffs/similar-tariffs/";
const SUITABLE_NUMBERS_LIST = "/api/tariffs/suitable-numbers/";
const SUITABLE_TARIFFS_LIST = "/api/numbers/suitable-tariffs/";
const DEVICE_SUITABLE_TARIFFS_LIST = "/api/device-suitable-tariffs/";
const NEWS_LIST = "/api/news/";
const SUBDOMAINS_LIST = "/api/subdomains/";
const FEEDBACKS_LIST = "/api/load-more-reviews/";
const ARTICLES_LIST = "/api/authors/articles/";
const STOCKS_LIST = "/api/stocks/";
const SEARCH_HEAP = "/api/search/heap/";

export function getNumbers(
  parameters,
  page = 1,
  pageSize = 20,
  endpoint = NUMBERS_LIST
) {
  page = ["page", page].join("=");
  pageSize = ["page_size", pageSize].join("=");

  parameters = [parameters, page, pageSize].filter(o => o).join("&");
  let url = [endpoint, parameters].join("?");

  return $.ajax({
    url: url
  });
}

export function getTariffs(
  params,
  page = 1,
  pageSize = 20,
  endpoint = TARIFFS_LIST
) {
  page = ["page", page].join("=");
  pageSize = ["page_size", pageSize].join("=");

  params = [params, page, pageSize].filter(o => o).join("&");
  let url = [endpoint, params].join("?");

  return $.ajax({
    url: url
  });
}

export function searchHeap(query) {
  return $.ajax({
    url: SEARCH_HEAP,
    data: $.param({
      query: query
    })
  });
}

export function getNumbersList(parameters, page = 1, pageSize = 20) {
  return getNumbers(parameters, page, pageSize);
}

export function getSuitableNumbersList(
  tariff,
  page = 1,
  pageSize = 20,
  parameters = "",
  endpoint = SUITABLE_NUMBERS_LIST
) {
  const tariffEndpoint = [endpoint, tariff, "/"].join("");
  return getTariffs(parameters, page, pageSize, tariffEndpoint);
}

export function getSuitableTariffsList(
  tariff,
  page = 1,
  pageSize = 20,
  parameters = "",
  endpoint = SUITABLE_TARIFFS_LIST
) {
  const tariffEndpoint = [endpoint, tariff, "/"].join("");
  return getTariffs(parameters, page, pageSize, tariffEndpoint);
}

export function getDeviceSuitableTariffsList(
  tariff,
  page = 1,
  pageSize = 20,
  parameters = "",
  endpoint = DEVICE_SUITABLE_TARIFFS_LIST
) {
  const tariffEndpoint = [endpoint, tariff, "/"].join("");
  return getTariffs(parameters, page, pageSize, tariffEndpoint);
}

export function getNews(params = "", page = 1) {
  page = ["page", page].join("=");
  params = [params, page].filter(o => o).join("&");
  params = [params].join("?");
  return $.ajax({
    url: NEWS_LIST,
    data: params
  });
}

export function getSubdomains() {
  return $.ajax({
    url: SUBDOMAINS_LIST
  });
}

export function getFeedbacks(page = 1) {
  let params;
  page = ["page", page].join("=");
  params = [page].join("?");
  return $.ajax({
    url: FEEDBACKS_LIST,
    data: params
  });
}

export function getArticles(params = "", page = 1) {
  page = ["page", page].join("=");
  params = [params, page].filter(o => o).join("&");
  params = [params].join("?");
  return $.ajax({
    url: ARTICLES_LIST,
    data: params
  });
}

export function getStocks(params = "", page = 1) {
  page = ["page", page].join("=");
  params = [params, page].filter(o => o).join("&");
  params = [params].join("?");
  return $.ajax({
    url: STOCKS_LIST,
    data: params
  });
}
